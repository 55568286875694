import { axiosInstance } from "helpers/axios";
const baseApiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const coachAvailabilityAPI = {
  // use in booking and amend
  async getAvailabilities(
    date: string,
    bookingModeCode: string,
    touchpointName: string[] | string,
    allowWaitingList?: boolean,
    hasCC4Profile?: boolean,
    currentSlotId?: string,
    cmpEligibleType?: string,
  ) {
    return await axiosInstance.get(
      `${baseApiEndpoint}/v2/coach-availability/availabilities`,
      {
        params: {
          date,
          bookingModeCode,
          touchpointName,
          allowWaitingList,
          hasCC4Profile,
          currentSlotId,
          cmpEligibleType
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
  },

  // use in booking and amend
  async getDayHasAvailabilities(
    bookingModeCode: string,
    touchpointName: string[] | string,
    allowWaitingList?: boolean,
    hasCC4Profile?: boolean,
    currentSlotId?: string,
    cmpEligibleType?: string,
  ) {
    return await axiosInstance.get(
      `${baseApiEndpoint}/v2/coach-availability/day-has-availabilities`,
      {
        params: {
          bookingModeCode,
          touchpointName,
          allowWaitingList,
          hasCC4Profile,
          currentSlotId,
          cmpEligibleType,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
  },
};

export default coachAvailabilityAPI;
