import { filterBy } from "@progress/kendo-data-query";
import bookingAPI from "api/v1/Booking.api";
import customerUserAPI from "api/v1/CustomerUser.api";
import { useToast } from "helpers/hook";
import { hasUserInfo } from "helpers/model";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingData } from "store/actions/booking";
import { BookingStep } from "types/types";

const useBookingCreateFunction = () => {
  const dispatch = useDispatch();
  const step = useSelector((state: any) => state.booking.step);
  const [isLoading, setIsLoading] = useState(false);
  const [occupationData, setOccupationData] = useState<any>({});
  const [filteringOccupation, setFilteringOccupation] = useState<any>("");

  const booking = useSelector((state: any) => state.booking);
  const userCC4Info = useSelector((state: any) => state.userCC4Info);

  const { toastError, toastSuccess, toastWarn } = useToast();

  const setStep = (step: BookingStep) => {
    dispatch(setBookingData({ field: "step", data: step }));
    if (window) {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  const setBookingGuid = (value: any) => {
    dispatch(setBookingData({ field: "guid", data: value }));
  };

  const setBookingUserInfo = (values: any) => {
    dispatch(setBookingData({ field: "userInfo", data: values }));
  };

  const setBookingApptInfo = (values: any) => {
    dispatch(setBookingData({ field: "bookingInfo", data: values }));
  };

  const getPrepareBookingData = async (userData: any, guid: any) => {
    try {
      setIsLoading(true);
      const response = await customerUserAPI.prepareBookingData({
        userData,
        guid,
        hasCC4Profile: hasUserInfo(userCC4Info),
      });
      setIsLoading(false);
      return response.data;
    } catch (error: any) {
      setIsLoading(false);
      toastError(error);
    }
  };

  const getOccupationsData = async (keyword = "", skip = 0, limit = 100) => {
    try {
      const response = await bookingAPI.listOccupation(skip, limit, keyword);
      setOccupationData({
        ...response.data,
        limit,
        keyword,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const filterOccupationChange = (event: any) => {
    if (event?.filter?.value) {
      getOccupationsData(event?.filter?.value);
      setFilteringOccupation(event?.filter?.value);
    } else {
      getOccupationsData();
      setFilteringOccupation("");
    }
  };

  const viewMoreOccupation = async () => {
    const nextPageData = await bookingAPI.listOccupation(
      occupationData?.data?.length,
      occupationData?.limit,
      occupationData?.keyword
    );

    const appendedData = occupationData?.data?.concat(nextPageData.data.data);
    setOccupationData({
      ...occupationData,
      data: appendedData,
    });
  };

  const occupations = useMemo(() => {
    let apiOccupationData: any[] =
      occupationData?.data?.map((e: any) => e.title) || [];

    if (
      userCC4Info?.occupation &&
      !filteringOccupation &&
      apiOccupationData.indexOf(userCC4Info?.occupation) == -1
    ) {
      apiOccupationData = [...apiOccupationData, userCC4Info.occupation];
    }

    return apiOccupationData;
  }, [occupationData, userCC4Info]);

  const canLoadMoreOccupation =
    occupationData?.total > occupationData?.data?.length;

  return {
    isLoading,
    step,
    booking,
    occupations,
    canLoadMoreOccupation,
    setStep,
    setBookingGuid,
    setBookingUserInfo,
    setBookingApptInfo,
    getPrepareBookingData,
    filterOccupationChange,
    getOccupationsData,
    viewMoreOccupation,
  };
};

export default useBookingCreateFunction;
