import * as React from "react";

type HeaderProps = {
  title: string;
  subTitle: string;
  addon?: any;
};

const Header = ({ title, subTitle, addon }: HeaderProps) => {
  return (
    <div
      className="k-container header-container k-py-8"
      style={{
        backgroundImage: "url(/images/header-bg.png)",
      }}
    >
      <h1>{title}</h1>
      <h4 className="k-mb-2 k-mt-4">{subTitle}</h4>
      {addon || <></>}
    </div>
  );
};

export default Header;
