import bookingAPI from "api/v1/Booking.api";
import bookingModeApi from "api/v1/BookingMode.api";
import coachAvailabilityAPI from "api/v1/CoachAvailability.api";
import customerUserAPI from "api/v1/CustomerUser.api";
import { useToast } from "helpers/hook";
import _ from "lodash";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ANOTHER_REASON_ADHOC_TYPE } from "types/constants";
import { BookingStep } from "types/types";
import useBookingCreateFunction from "./booking-create.hook";
import { hasUserInfo } from "helpers/model";

const INIT_SELECT_DATE_OIC = {
  originCAId: null,
  selectingOIC: null,
  availableSlotOption: [],
  availableOICOption: [],
};

const useBookingCreateAppointmentFunction = () => {
  const editorRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendTouchpoint, setRecommendTouchpoint] = useState<any>("");
  const [coachAvailabilityId, setCoachAvailabilityId] = useState<string>("");
  const [bookingDate, setBookingDate] = useState<string>("");
  const [allTouchpoints, setAllTouchpoints] = useState<any>([]);
  const [selectedTouchpoint, setSelectedTouchpoint] = useState<string[]>([]);
  const [bookingModeCodes, setBookingModeCodes] = useState<any[]>([]);
  const [touchpointMapBookingMode, setTouchpointMapBookingMode] = useState<
    any[]
  >([]);
  const [dayHasAvaibilities, setDayHasAvaibilities] = useState<any[]>([]);
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [isSlotWaitingList, setIsSlotWaitingList] = useState<boolean>(false);
  const [eligibleStatus, setEligibleStatus] = useState<any | null>(null);
  const [selectedBookingModeCode, setSelectedBookingModeCode] =
    useState<string>("");
  const [selectDateOIC, setSelectDateOIC] = useState<{
    originCAId: any;
    selectingOIC: any;
    availableSlotOption: any[];
    availableOICOption: any[];
  }>(INIT_SELECT_DATE_OIC);
  const [loadingDayHasSlot, setLoadingDayHasSlot] = useState<boolean>(false);
  const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
  const { setStep, setBookingApptInfo } = useBookingCreateFunction();
  const { toastError, toastSuccess, toastWarn } = useToast();
  const bookingUserInfo = useSelector((state: any) => state.booking.userInfo);
  const userCC4Info = useSelector((state: any) => state.userCC4Info);
  const customerGuid = useSelector((state: any) => state.booking.guid);
  const trackingCode = useSelector((state: any) => state.trackingCode);

  const getBookingMode = async () => {
    const touchpointName = selectedTouchpoint[0];
    if (touchpointName) {
      const dataPreload = touchpointMapBookingMode.find(
        (e: any) => e.touchpoint.name === touchpointName
      );
      if (dataPreload && dataPreload.bookingModes) {
        setBookingModeCodes(dataPreload.bookingModes);
      }
    }
  };

  const getPrepareBookingData = async (userData: any, guid: any) => {
    try {
      setIsLoading(true);

      const { data } = await customerUserAPI.prepareBookingData({
        userData,
        guid,
        hasCC4Profile: hasUserInfo(userCC4Info),
      });

      let allAvailTouchpoint: any[] = [];

      if (data.another_touchpoint?.length > 0) {
        allAvailTouchpoint = allAvailTouchpoint.concat(data.another_touchpoint);
      }

      if (data.recommend_touchpoint) {
        setRecommendTouchpoint(data.recommend_touchpoint);
        editorRef?.current?.onChange("recommendedTouchpoint", {
          value: `${data.recommend_touchpoint.locationName} (Recommended based on Postal Code)`,
        });
        setSelectedTouchpoint([data.recommend_touchpoint.name]);
        allAvailTouchpoint.unshift(data.recommend_touchpoint);
      }
      setEligibleStatus(data.eligible_status);
      setAllTouchpoints(allAvailTouchpoint);
      setTouchpointMapBookingMode(data.touchpoint_booking_modes);
      setIsLoading(false);
      return data;
    } catch (error: any) {
      setIsLoading(false);
      toastError(error);
    }
  };

  const getDayHasCoachAvailability = async () => {
    setLoadingDayHasSlot(true);
    const { data } = await coachAvailabilityAPI.getDayHasAvailabilities(
      selectedBookingModeCode,
      selectedTouchpoint,
      true,
      hasUserInfo(userCC4Info),
      undefined,
      eligibleStatus?.cmpEligibleType || undefined
    );
    setDayHasAvaibilities(data);
    setLoadingDayHasSlot(false);
  };

  const getCoachAvailabilities = async () => {
    setLoadingSlots(true);
    const { data } = await coachAvailabilityAPI.getAvailabilities(
      bookingDate,
      selectedBookingModeCode,
      selectedTouchpoint,
      true,
      hasUserInfo(userCC4Info),
      undefined,
      eligibleStatus?.cmpEligibleType || null
    );
    // group by time to remove dupplicate
    const timeMapped = data.map((m: any) => ({
      id: m.id,
      coach: {
        coachAvailabilityId: m.id,
        coachAvailabilityTouchpointId: m.touchpointId,
        isSlotWaitingList: m.isSlotWaitingList,
        oicId: m.coach.id + (m.subCoachId ? "-" + m.subCoachId : ""),
        subCoachId: m.subCoachId,
        subCoachFirstName: m.subCoach?.firstName,
        subCoachLastName: m.subCoach?.lastName,
        subCoachPosition: m.subCoach?.position,
        subCoachDefaultTouchpointName: m.subCoach?.defaultTouchpointName,
        ...(m.coach || {}),
      },
      day: `${moment(m.startAt).format("DD/MM/yyyy")}`,
      text: `${moment(m.startAt).format("HH:mm")} - ${moment(m.endAt).format(
        "HH:mm"
      )}`,
      address: m.address || "",
      touchpointId: m.touchpointId,
      touchpointName: m.touchpoint?.name || "",
      touchpointLocationName: m.touchpoint?.locationName || "",
    }));

    setAvailabilities(timeMapped);
    setLoadingSlots(false);
  };

  const currentBookingMode = () => {
    return bookingModeCodes.find(
      (e: any) => e.code === selectedBookingModeCode
    );
  };

  const refreshSlotSelection = (formRenderProps: any) => {
    formRenderProps.onChange("coachAvailabilityId", { value: null });
    setCoachAvailabilityId("");
    setSelectDateOIC(INIT_SELECT_DATE_OIC);
  };

  const onDateChange = async ({ value }: { value: string }) => {
    setBookingDate(value);
  };

  const clearDayHasCoachAvailability = async () => {
    setLoadingDayHasSlot(false);
    setDayHasAvaibilities([]);
  };

  const clearCoachAvailability = async () => {
    setLoadingSlots(false);
    setAvailabilities([]);
  };

  const anotherTouchpointOptions = useMemo(() => {
    let options: any[] = allTouchpoints?.map((e: any) => ({
      text: e.locationName,
      value: e.name,
      id: e.id,
    }));

    if (recommendTouchpoint) {
      options = options.filter((e: any) => e.id !== recommendTouchpoint.id);
      options.push({
        text: "(not select)",
        value: null,
      });
    }
    return options;
  }, [allTouchpoints, recommendTouchpoint]);

  const onAnotherTouchpointChange = async ({ value }: { value: any }) => {
    if (value?.text === "(not select)" && recommendTouchpoint) {
      setSelectedTouchpoint([recommendTouchpoint.name]);
      return;
    }

    if (!value?.value) {
      setSelectedTouchpoint([]);
      return;
    }
    // if (value.value === TPSpecialValue.all) {
    //   let allName = touchpointsAvail?.map((e) => e.name);
    //   if (allName?.length) {
    //     if (recommendTouchPoint) {
    //       allName = [...allName, recommendTouchPoint.name];
    //     }
    //     setSelectedTouchpoint(allName);
    //   }
    //   return;
    // }

    // if (value.value === TPSpecialValue.all_wsg) {
    //   const allWSGName = touchpointsAvail
    //     ?.filter((e) => e.organizationId === OrgId.WSG)
    //     ?.map((e) => e.name);
    //   if (allWSGName?.length) {
    //     setSelectedTouchpoint(allWSGName);
    //   }
    //   return;
    // }

    setSelectedTouchpoint([value.value]);
  };

  const selectRandomSlotInTime = (timeRange: any) => {
    const currentTouchoint = allTouchpoints.find(
      (tp: any) => tp.name === selectedTouchpoint[0]
    );
    const bookingMode = currentBookingMode();
    if (bookingMode?.bookingMode === "F2F") {
      return timeRange.ids[Math.floor(Math.random() * timeRange.ids.length)];
    } else {
      const currentTpSlots = timeRange.coachInfo.filter(
        (info: any) =>
          info.coachAvailabilityTouchpointId === currentTouchoint.id
      );
      const otherTpSlots = timeRange.coachInfo.filter(
        (info: any) =>
          info.coachAvailabilityTouchpointId !== currentTouchoint.id
      );
      if (currentTpSlots?.length > 0) {
        const ids = currentTpSlots.map((info: any) => info.coachAvailabilityId);
        return ids[Math.floor(Math.random() * ids.length)];
      } else {
        const ids = otherTpSlots.map((info: any) => info.coachAvailabilityId);
        return ids[Math.floor(Math.random() * ids.length)];
      }
    }
  };

  const onChangeSlot = (timeRange: any) => {
    if (timeRange) {
      const coachAvailabilityId = selectRandomSlotInTime(timeRange);
      setCoachAvailabilityId(coachAvailabilityId);

      const selectableOIC = _.unionBy(timeRange?.coachInfo, "oicId");
      const selectingOIC = timeRange?.coachInfo.find(
        (e: any) => e.coachAvailabilityId === coachAvailabilityId
      );

      if ((selectingOIC as any).isSlotWaitingList) {
        toastWarn(
          "Selecting a full capacity slot, booking will be in waiting list"
        );
        setIsSlotWaitingList(true);
      } else {
        setIsSlotWaitingList(false);
      }

      setSelectDateOIC({
        originCAId: coachAvailabilityId,
        selectingOIC: selectingOIC,
        availableOICOption: selectableOIC,
        availableSlotOption: timeRange?.coachInfo,
      });
      editorRef?.current.onChange("changeDateCoachId", {
        // tddo fix here
        value: {
          value: 0,
        },
      });
    }
  };

  const onBookingModeCodeChange = async ({ value }: { value: any }) => {
    if (value?.value) {
      setSelectedBookingModeCode(value.value);
    } else {
      setSelectedBookingModeCode("");
    }
  };

  const handleAddBooking = async (values: { [name: string]: any }) => {
    try {
      const addBookingPayload = {
        coachAvailabilityId: coachAvailabilityId,
        customerUserGuid: customerGuid,
        bookingModeCode: selectedBookingModeCode,
        remark: values.remark,
        anotherReason:
          values.anotherReason === ANOTHER_REASON_ADHOC_TYPE
            ? values.anotherReasonText
            : values.anotherReason,
        userData: userCC4Info
          ? {
              ...userCC4Info,
              ...bookingUserInfo,
            }
          : bookingUserInfo,
        trackingCode: trackingCode || "",
        userCC4Data: userCC4Info || null,
        eligibleStatus: JSON.stringify(eligibleStatus),
        recommendedTouchpoint: recommendTouchpoint?.name || "NIL",
      };

      const bookingRes = await bookingAPI.create(addBookingPayload);

      if (bookingRes?.data?.getMeetingLinkError) {
        toastError(bookingRes?.data?.getMeetingLinkError, {
          autoClose: 15000,
        });
      } else {
        toastSuccess("Booking is successful.");
        setBookingApptInfo(bookingRes.data.booking);
        const clearer = setTimeout(() => {
          setStep(BookingStep.Confirmation);
          clearTimeout(clearer);
        }, 300);
      }
    } catch (error: any) {
      toastError(error);
    }
  };

  return {
    customerGuid,
    bookingUserInfo,
    editorRef,
    isLoading,
    loadingSlots,
    bookingDate,
    loadingDayHasSlot,
    selectedBookingModeCode,
    selectedTouchpoint,
    coachAvailabilityId,
    recommendTouchpoint,
    eligibleStatus,

    availabilities,
    dayHasAvaibilities,
    bookingModeCodes,
    anotherTouchpointOptions,

    getBookingMode,
    getPrepareBookingData,
    getDayHasCoachAvailability,
    getCoachAvailabilities,

    clearCoachAvailability,
    clearDayHasCoachAvailability,
    refreshSlotSelection,
    handleAddBooking,

    currentBookingMode,
    onChangeSlot,
    onDateChange,
    onAnotherTouchpointChange,
    onBookingModeCodeChange,
  };
};

export default useBookingCreateAppointmentFunction;
