import Timed from "components/atoms/Timed.shared";
import PageContainer from "components/layout/PageContainer.shared";
import PageContentContainer from "components/layout/PageContentContainer.shared";
import StepAppointment from "components/page-section/booking/StepAppointment.section";
import StepConfirmation from "components/page-section/booking/StepConfirmation.section";
import StepContact from "components/page-section/booking/StepContact.section";
import Stepper from "components/page-section/booking/Stepper.section";
import TrackingCode from "components/page-section/booking/TrackingCode.section";
import Header from "components/shared/Header.shared";
import useBookingCreateFunction from "hooks/booking-create.hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BookingStep } from "types/types";

const CreateBookingPage = () => {
  const { step, setBookingGuid, setBookingUserInfo, setBookingApptInfo } =
    useBookingCreateFunction();
  const guid = useSelector((state: any) => state.guid);

  useEffect(() => {
    if (guid) {
      setBookingGuid(guid);
      setBookingUserInfo(null);
      setBookingApptInfo(null);
    }
  }, [guid]);

  const renderStepContent = () => {
    switch (step) {
      case BookingStep.Contact:
        return <StepContact />;
      case BookingStep.Appointment:
        return <StepAppointment />;
      case BookingStep.Confirmation:
        return <StepConfirmation />;
      default:
        return <></>;
    }
  };

  const pageTitle = () => {
    if (step === BookingStep.Confirmation) {
      return "Your Appointment is Confirmed";
    }
    return "Book Your Appointment here";
  };

  const pageSubTitle = () => {
    if (step === BookingStep.Confirmation) {
      return "Please check your email for details";
    }
    return "";
  };

  const pageAddon = () => {
    if (step === BookingStep.Contact) {
      return (
        <Timed
          addon={
            <p className="k-m-0 k-font-weight-bold text-color-white">
              &#42;All information are required
            </p>
          }
        />
      );
    }

    if (step === BookingStep.Appointment) {
      return <Timed />;
    }

    return <></>;
  };

  return (
    <div className="container">
      <Header
        title={pageTitle()}
        subTitle={pageSubTitle()}
        addon={pageAddon()}
      />
      <PageContainer>
        <TrackingCode />
        <Stepper value={step} />
        <PageContentContainer>{renderStepContent()}</PageContentContainer>
      </PageContainer>
    </div>
  );
};

export default CreateBookingPage;
