export const ROUTE_PATH = {
  HOME: "/",
  BOOKING_LIST: "/bookings",
  CREATE_BOOKING: "/booking/create",
  EDIT_BOOKING: "/booking/:id/edit",
  
  CONFIRM_ORGANIZATION: "/confirm/organization",
  UNABLE_PROCESS: "/errors/unable-process",
  INVALID_GUID: "/errors/invalid-guid",
  NOT_FOUND: "*",
};
