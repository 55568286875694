import bookingAPI from "api/v1/Booking.api";
import organizationAPI from "api/v1/Organization.api";
import customerUserAPI from "api/v1/CustomerUser.api";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingsData } from "store/actions/booking";

const useHomePageFunction = () => {
  const dispatch = useDispatch();
  const bookings = useSelector((state: any) => state.bookings);
  const userInfo = useSelector((state: any) => state.userInfo);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingL1, setIsCheckingL1] = useState(false);

  const haveBooking = bookings?.length > 0;

  const headerTitle =
    userInfo && userInfo?.preferredName
      ? `Dear, ${userInfo.preferredName}`
      : "Dear";

  const headerDescription = haveBooking
    ? `You have the following appointment(s)`
    : `Book your appointment here`;

  const getBookings = async () => {
    setIsLoading(true);
    const response = await bookingAPI.list();
    setIsLoading(false);
    dispatch(setBookingsData(response.data));
  };

  const getAllOrganization = async () => {
    const response = await organizationAPI.list();
    setOrganizations(response.data);
  };

  const checkL1User = async () => {
    if (!userInfo) {
      // by pass L1 check if CC4 have no data
      return true;
    }
    setIsCheckingL1(true);
    const response = await customerUserAPI.checkingL1(userInfo);
    setIsCheckingL1(false);
    return response.data.l1CheckResult;
  };

  return {
    userInfo,
    organizations,
    haveBooking,
    bookings,
    isLoading,
    headerTitle,
    headerDescription,
    isCheckingL1,

    getBookings,
    checkL1User,
    getAllOrganization,
  };
};

export default useHomePageFunction;
