import { EMPLOY_STATUS_OPTIONS } from "types/constants";
import { BookingModeFlow, MSFRole } from "./const";

export function mapCoachTextFullInfo(m: any) {
  if (!m) {
    return {
      value: null,
      text: "",
      defaultTouchpointId: null,
    };
  }
  let text = `${m.firstName} ${m.lastName}`;
  if (m.email) {
    text = `${text} [${m.email}]`;
  }
  if (m.position) {
    text = `${text} [${m.position}]`;
  }
  if (m.defaultTouchpointName) {
    text = `${text} [${m.defaultTouchpointName}]`;
  }
  if (m.languages?.length > 0) {
    text = `${text} [${m.languages.map((e: any) => e.language).join(", ")}]`;
  }
  return {
    value: m.id,
    text,
    defaultTouchpointId: m.defaultTouchpointId || null,
  };
}

export function mapCoachTextWithPostionSupportSubCoach(m: any) {
  if (!m) {
    return {
      value: null,
      text: "",
      defaultTouchpointId: null,
    };
  }
  let text = `${m.firstName} ${m.lastName}`;
  if (m.position) {
    text = `${text} [${m.position}]`;
  }
  if (m.languages?.length > 0) {
    text = `${text} [${m.languages.map((e: any) => e.language).join(", ")}]`;
  }
  if (m.defaultTouchpointName) {
    text = `${text} [${m.defaultTouchpointName}]`;
  }

  if (m.subCoachFirstName || m.subCoachLastName) {
    text = `${text} & ${m.subCoachFirstName} ${m.subCoachLastName}`;
  }

  if (m.subCoachPosition) {
    text = `${text} [${m.subCoachPosition}]`;
  }

  if (m.subCoachDefaultTouchpointName) {
    text = `${text} [${m.subCoachDefaultTouchpointName}]`;
  }

  return {
    value: m.oicId,
    text,
    defaultTouchpointId: m.defaultTouchpointId || null,
  };
}

export function mapCoachTextWithPostionSupportSubCoachById(m: any) {
  if (!m) {
    return {
      value: null,
      text: "",
      defaultTouchpointId: null,
    };
  }
  let text = `${m.firstName} ${m.lastName}`;
  if (m.position) {
    text = `${text} [${m.position}]`;
  }
  if (m.languages?.length > 0) {
    text = `${text} [${m.languages.map((e: any) => e.language).join(", ")}]`;
  }
  if (m.defaultTouchpointName) {
    text = `${text} [${m.defaultTouchpointName}]`;
  }

  if (m.subCoachFirstName || m.subCoachLastName) {
    text = `${text} & ${m.subCoachFirstName} ${m.subCoachLastName}`;
  }

  if (m.subCoachPosition) {
    text = `${text} [${m.subCoachPosition}]`;
  }

  if (m.subCoachDefaultTouchpointName) {
    text = `${text} [${m.subCoachDefaultTouchpointName}]`;
  }

  return {
    value: m.id,
    text,
    defaultTouchpointId: m.defaultTouchpointId || null,
  };
}

export const touchpointName = (touchpoint: any) => {
  return `${touchpoint?.name || ""}${
    touchpoint?.eligible ? " [Eligible]" : ""
  }${touchpoint?.recommend ? " [Recommended]" : ""}`;
};

// Former logic WorkshopFlow = Workshop + Career Ignite
export const isWorkshopFlow = (bookingMode: any) => {
  return bookingMode?.bookingFlow === BookingModeFlow.Workshop;
};

export const isWorkshopBookingCheckExist = (bookingMode: any) => {
  return bookingMode?.bookingType.label === BookingModeFlow.Workshop;
};

export const safeParse = (jsonField: any) => {
  try {
    if (typeof jsonField === "object") return jsonField;
    if (jsonField) return JSON.parse(jsonField);
    return null;
  } catch {
    return null;
  }
};

export const checkIsBelongOrg = (uam: any, itemTPOrgId: number) => {
  let isBelongOrg = false;
  if (uam?.isPOD) {
    isBelongOrg =
      itemTPOrgId === uam?.belongingOrgs?.[0]?.id ||
      itemTPOrgId === 2 ||
      itemTPOrgId === 3;
  } else {
    isBelongOrg = itemTPOrgId === uam?.belongingOrgs?.[0]?.id;
  }
  return isBelongOrg;
};

export const orgMSFByRole = (role: any) => {
  if (MSFRole.includes(role?.name)) {
    return "MSF";
  }
};

export const hasUserInfo = (userCC4Info: any) => {
  return !!(
    userCC4Info?.preferredName &&
    userCC4Info?.emailAddress &&
    userCC4Info?.mobileNumber &&
    userCC4Info?.registeredPostalCode &&
    userCC4Info?.emailAddress &&
    userCC4Info?.ssocCode &&
    userCC4Info?.highestQualification &&
    userCC4Info?.idType &&
    (userCC4Info?.employmentStatus === EMPLOY_STATUS_OPTIONS[0] ||
      userCC4Info?.employmentStatus === EMPLOY_STATUS_OPTIONS[1] ||
      (userCC4Info?.employmentStatus === EMPLOY_STATUS_OPTIONS[2] &&
        userCC4Info?.unemploymentReason &&
        userCC4Info?.unemploymentSince))
  );
};
