import Timed from "components/atoms/Timed.shared";
import PageContainer from "components/layout/PageContainer.shared";
import PageContentContainer from "components/layout/PageContentContainer.shared";
import AmendBooking from "components/page-section/booking/AmendBooking.section";
import Header from "components/shared/Header.shared";

const EditBookingPage = () => {
  return (
    <div className="container">
      <Header
        title="Your Booking Information"
        subTitle="Your confirmed Appointment details is as follows."
        addon={<Timed />}
      />
      <PageContainer>
        <PageContentContainer>
          <AmendBooking />
        </PageContentContainer>
      </PageContainer>
    </div>
  );
};

export default EditBookingPage;
