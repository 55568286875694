import { axiosInstance } from "helpers/axios";

const customerUserAPI = {
  prepareBookingData(cc4InputData: any) {
    return axiosInstance.post(
      `/v2/customer-user/prepare-booking-data`,
      cc4InputData
    );
  },

  prepareAmendData(guid: string, bookingId: number, hasCC4Profile: boolean) {
    return axiosInstance.post(`/v2/customer-user/prepare-amend-data`, {
      guid,
      bookingId,
      hasCC4Profile
    });
  },

  checkingL1(userData: any) {
    return axiosInstance.post(`/v2/customer-user/check-l1`, {
      userData,
    });
  },
};

export default customerUserAPI;
