import Header from "components/shared/Header.shared";

const InvalidTokenPage = () => {
  return (
    <div className="container">
      <Header
        title="Invalid Public page URL"
        subTitle="Please check ABS url or token was expired"
      />
    </div>
  );
};

export default InvalidTokenPage;
