import {
  Button as KendoButton,
  ButtonProps as KendoButtonProps,
} from "@progress/kendo-react-buttons";
import * as React from "react";

type ButtonProps = {
  text: any;
  onClick?: any;
  size?: "large" | "medium";
  theme?: "primary" | "light";
  className?: string;
  mobileSize?: "mobile-full";
} & KendoButtonProps;

const Button = ({
  text,
  onClick,
  className,
  mobileSize,
  size = "medium",
  theme = "primary",
  ...otherProps
}: ButtonProps) => {
  return (
    <KendoButton
      onClick={onClick}
      className={`rounded-8 app-button ${theme || ""} ${size || ""} ${
        className || ""
      } ${mobileSize || ""}`}
      {...otherProps}
    >
      {text}
    </KendoButton>
  );
};

export default Button;
