import { Loader } from "@progress/kendo-react-indicators";

const LoadingFullSite = ({ message }: { message: string }) => {
  return (
    <div className="page-loading">
      <div className="k-d-flex k-flex-column k-align-items-center">
        <Loader size="large" type={"converging-spinner"} />
        <h5 className="fs-16">{message}</h5>
      </div>
    </div>
  );
};

export default LoadingFullSite;
