import Timed from "components/atoms/Timed.shared";
import PageContainer from "components/layout/PageContainer.shared";
import KickStart from "components/page-section/home/KickStart.section";
import ListBooking from "components/page-section/home/ListBooking.section";
import LoadingFullSite from "components/page-section/proxy/LoadingFullSite.section";
import Header from "components/shared/Header.shared";
import useHomePageFunction from "hooks/home-page.hook";
import { useEffect } from "react";

const HomePage = () => {
  const {
    bookings,
    haveBooking,
    isLoading,
    headerTitle,
    headerDescription,
    getBookings,
  } = useHomePageFunction();

  useEffect(() => {
    getBookings();
  }, []);

  if (isLoading) {
    return <LoadingFullSite message="Loading Booking Data ..." />;
  }

  return (
    <div className="container">
      <Header
        title={headerTitle}
        subTitle={headerDescription}
        addon={<Timed />}
      />
      <PageContainer>
        {haveBooking ? <ListBooking bookings={bookings} /> : <KickStart />}
      </PageContainer>
    </div>
  );
};

export default HomePage;
