import Header from "components/shared/Header.shared";

const NotFoundPage = () => {
  return (
    <div className="container">
      <Header
        title="Page not found"
        subTitle="Return back to home to find your booking"
      />
    </div>
  );
};

export default NotFoundPage;
