import Button from "components/atoms/Button.shared";
import { useWindow } from "helpers/hook";
import { useMemo } from "react";

const UnableProcess = () => {
  const { closeWindow } = useWindow();
  const onClickClose = () => {
    closeWindow();
  };
  const organization = useMemo(() => {
    let orgData = null
    try {
      orgData = JSON.parse(localStorage.getItem('lastOrg') || '')
      return orgData
    } catch (error) {
      console.log(error);
    }
  }, [])

  return (
    <div className="kickstart-container">
      <div
        style={{
          width: "60%",
          minHeight: "50vh",
          marginTop: "3vh",
        }}
        className="bg-white-50 rounded-16 m-auto k-p-8 k-justify-content-between k-d-flex k-d-flex-col"
      >
        <div className="k-text-center">
          <p className="k-text-justify k-mb-6">
            We are unable to process your request for career services appointment as you were recently assisted by {organization.uenName}. We encourage you to contact {organization.uenName} at {organization.contactNo} to continue working with them as they are familiar with your employment situation.
            <br/>For further assistance, please contact WSG at 6883 5885.
            Thank you.
          </p>
        </div>
        <div className="k-text-center">
          <Button
            text="Close"
            size="large"
            className="k-mt-5"
            onClick={onClickClose}
          />
        </div>
      </div>
    </div>
  );
};

export default UnableProcess;
