import Button from "components/atoms/Button.shared";
import { BookingModeMode } from "helpers/const";
import { useCustomNavigate, useWindow } from "helpers/hook";
import { timeStrToFormatDay, timeStrToFormatTime } from "helpers/time";
import { useDispatch } from "react-redux";
import { setAmendingBookingData } from "store/actions/booking";
import { ROUTE_PATH } from "types/route-path";

const BookingItem = ({ booking }: { booking: any }) => {
  const { navigateWithQueryString } = useCustomNavigate();
  const dispatch = useDispatch();
  const onClickEdit = () => {
    dispatch(setAmendingBookingData(null))
    const timeout = setTimeout(() => {
      navigateWithQueryString(
        ROUTE_PATH.EDIT_BOOKING?.replace(":id", booking.id)
      );
      clearTimeout(timeout)
    }, 200)
  };

  const isShowAddress = booking.bookingModeMode === BookingModeMode.F2F;
  return (
    <div
      className="bg-white-50 rounded-16 k-px-5 k-py-4 k-float-left k-overflow-auto k-mx-3 k-mb-6"
      style={{ width: "300px", overflow: "hidden", height: 280 }}
    >
      <div
        className="bg-white border-grey k-align-items-center k-d-flex k-justify-content-center k-p-3 k-text-center rounded-16"
        style={{ minHeight: "200px", height: "90%" }}
      >
        <div>
          <div className="k-mb-2 k-overflow-auto">
            <p className="fs-14">
              <span className="lablel-grey">ID:</span>
              <span> {booking?.referenceNumber || ""}</span>
            </p>
            <p className="fs-14">
              <span className="lablel-primary bg-grey-50 k-py-2 k-px-3 rounded-8">
                {booking?.bookingModeName || ""}
              </span>
            </p>
          </div>
          <div className="k-mb-2 k-overflow-auto">
            <p className="fs-14 k-m-1">
              <span className="lablel-grey">Date:</span>
              <span>
                {" "}
                {timeStrToFormatDay(booking?.start)}{" "}
                <span className="lablel-grey">at</span>{" "}
                {timeStrToFormatTime(booking?.start)}
              </span>
            </p>
            {isShowAddress && (
              <>
                <p className="fs-14 k-m-1">
                  <span className="lablel-grey">Location:</span>
                  <span> {booking?.touchpointLocationName || ""}</span>
                </p>
                <p className="fs-14 k-m-1">
                  <span className="lablel-grey">Address:</span>
                  <span className="lablel-italic">
                    {" "}
                    {booking?.touchpointLocationAddress || ""}
                  </span>
                </p>
              </>
            )}
          </div>
          <Button
            text={"Reschedule / Cancel"}
            onClick={onClickEdit}
          />
        </div>
      </div>
    </div>
  );
};

const ListBooking = ({ bookings }: { bookings: any[] }) => {
  const { closeWindow } = useWindow();
  const handleClose = () => {
    closeWindow();
  }

  return (
    <>
      <div className="mx--3 k-overflow-auto">
        {bookings?.map((booking) => (
          <BookingItem booking={booking} />
        ))}
      </div>
      <div className={`${bookings.length < 3 ? 'k-bottom-center k-fixed' : ''} k-mt-4 k-pb-6 k-text-center`}>
        <Button
          type={"button"}
          text={"Close"}
          theme={"light"}
          size={"large"}
          disabled={false}
          onClick={handleClose}
        />
      </div>
    </>
  );
};

export default ListBooking;
