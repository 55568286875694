import Button from "components/atoms/Button.shared";
import { useCustomNavigate, useWindow } from "helpers/hook";
import useHomePageFunction from "hooks/home-page.hook";
import { useEffect } from "react";
import { ROUTE_PATH } from "types/route-path";

const OrganizationConfirm = () => {
  const { closeWindow } = useWindow();
  const { navigateWithQueryString } = useCustomNavigate();
  useEffect(() => {
    getAllOrganization();
  }, []);

  const onClickBack = () => {
    navigateWithQueryString(ROUTE_PATH.HOME);
  };

  const onClickContinue = () => {
    navigateWithQueryString(ROUTE_PATH.CREATE_BOOKING);
  };
  const { userInfo, organizations, getAllOrganization } = useHomePageFunction();

  const userProfileOrg = userInfo?.organizations;

  const displayOrgs = organizations?.filter((e: any) =>
    userProfileOrg?.includes(e.uenName)
  );

  console.log(displayOrgs);
  return (
    <div className="kickstart-container">
      <div
        style={{
          width: "60%",
          minHeight: "50vh",
          marginTop: "3vh",
        }}
        className="bg-white-50 rounded-16 m-auto k-p-8 k-justify-content-between k-d-flex k-d-flex-col"
      >
        <div className="k-text-center">
          <p className="k-text-justify k-mb-6">
            Are you want to go back to following organization to contact hotline
            or continue proceed to make a booking to another organization via
            self-booking?
          </p>
          <ul className="k-text-left">
            {displayOrgs?.map((org: any) => (
              <li className="k-mb-2">
                {org.organizationName}, contact hotline: {org.contactNo}
              </li>
            ))}
          </ul>
        </div>
        <div className="k-text-center">
          <Button
            text={"Back"}
            theme={"light"}
            size={"large"}
            onClick={onClickBack}
            className={"k-mr-20"}
          />
          <Button text="Proceed" size="large" onClick={onClickContinue} />
        </div>
      </div>
    </div>
  );
};

export default OrganizationConfirm;
