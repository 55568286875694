import { Field, Form, FormElement } from "@progress/kendo-react-form";
import Button from "components/atoms/Button.shared";
import { FormDropDownList, FormInput } from "components/form/FormInputs";
import Card from "components/layout/Card.shared";
import FormRow from "components/layout/FormRow.shared";
import {
  emailValidator,
  isUnemployed,
  numberValidator,
  postalCodeValidator,
  requiredStrValidator,
  requiredValidator
} from "helpers/validator";
import useBookingCreateFunction from "hooks/booking-create.hook";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  AGE_OPTIONS,
  EMPLOY_STATUS_OPTIONS,
  HIGHEST_EDUCATION_OPTIONS,
  UNEMPLOYMENT_DURATION_OPTIONS,
  UNEMPLOYMENT_REASON_OPTIONS,
} from "types/constants";
import { BookingStep } from "types/types";

type StepContactProps = {};

// eslint-disable-next-line
const StepContact = ({ }: StepContactProps) => {
  const editorRef = useRef<any>(null);
  const userInfo = useSelector((state: any) => state.userInfo);
  const bookingUserInfo = useSelector((state: any) => state.booking.userInfo);
  const initFormData = bookingUserInfo
    ? { ...bookingUserInfo }
    : { ...userInfo };

  const {
    setStep,
    setBookingUserInfo,
    filterOccupationChange,
    getOccupationsData,
    viewMoreOccupation,
    occupations,
    canLoadMoreOccupation,
  } = useBookingCreateFunction();

  useEffect(() => {
    getOccupationsData();
  }, []);

  useEffect(() => {
    if (editorRef && initFormData) {
      // workaround enable submit without change
      const currentValue = editorRef.current.valueGetter('preferredName')
      editorRef.current.onChange("preferredName", {
        value: currentValue || (initFormData.preferredName || ""),
      });
    }
  }, [editorRef, initFormData]);

  const handleAddBooking = (values: { [name: string]: any }) => {
    if (values.employmentStatus === EMPLOY_STATUS_OPTIONS[0] || values.employmentStatus === EMPLOY_STATUS_OPTIONS[1]) {
      values.unemploymentReason = null
      values.unemploymentDuration = null
    }
    setBookingUserInfo(values);
    setStep(BookingStep.Appointment);
  };

  return (
    <Form
      initialValues={initFormData}
      onSubmit={handleAddBooking}
      ref={editorRef}
      render={(formRenderProps) => (
        <FormElement horizontal={true}>
          <div>
            <Card title="Contact Information">
              <FormRow>
                <Field
                  id={"preferredName"}
                  name={"preferredName"}
                  label="Name"
                  validator={requiredStrValidator}
                  component={FormInput}
                  disabled={!!userInfo?.preferredName}
                />
                <Field
                  id={"age"}
                  name={"age"}
                  label="Age (as of booking date, i.e today)"
                  data={AGE_OPTIONS}
                  validator={requiredValidator}
                  component={FormDropDownList}
                />
              </FormRow>
              <FormRow>
                <Field
                  id={"emailAddress"}
                  name={"emailAddress"}
                  label="Email"
                  validator={emailValidator}
                  component={FormInput}
                  hint="An appointment confirmation will be sent to the registered email address and phone number. Please ensure that the information is correct."
                />
                <Field
                  id={"mobileNumber"}
                  name={"mobileNumber"}
                  label="Mobile"
                  validator={requiredStrValidator}
                  component={FormInput}
                />
              </FormRow>
              <FormRow>
                <Field
                  id={"registeredPostalCode"}
                  name={"registeredPostalCode"}
                  label="Postal Code"
                  validator={postalCodeValidator}
                  component={FormInput}
                />
              </FormRow>
            </Card>
            <Card title="Employment Info" containerClass="k-mt-4">
              <FormRow>
                <Field
                  id={"employmentStatus"}
                  name={"employmentStatus"}
                  label="Employment Status"
                  data={EMPLOY_STATUS_OPTIONS}
                  validator={requiredValidator}
                  component={FormDropDownList}
                />
              </FormRow>
              {isUnemployed(formRenderProps) && (
                <FormRow>
                  <Field
                    id={"unemploymentReason"}
                    name={"unemploymentReason"}
                    label="Reason for Unemployment"
                    data={UNEMPLOYMENT_REASON_OPTIONS}
                    validator={requiredValidator}
                    component={FormDropDownList}
                    disabled={!isUnemployed(formRenderProps)}
                  />
                  <Field
                    id={"unemploymentDuration"}
                    name={"unemploymentDuration"}
                    label="Duration of Unemployment"
                    data={UNEMPLOYMENT_DURATION_OPTIONS}
                    validator={requiredValidator}
                    component={FormDropDownList}
                    disabled={!isUnemployed(formRenderProps)}
                  />
                </FormRow>
              )}
              <FormRow>
                <Field
                  id={"occupation"}
                  name={"occupation"}
                  label="Occupation"
                  data={occupations}
                  validator={requiredValidator}
                  component={FormDropDownList}
                  filterable={true}
                  onFilterChange={filterOccupationChange}
                  footer={
                    canLoadMoreOccupation ? (
                      <div
                        className="k-text-center"
                        onClick={(e) => {
                          viewMoreOccupation();
                          e.stopPropagation();
                        }}
                      >
                        <span className="k-text-primary fs-13 k-cursor-pointer">View More</span>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  show={true}
                />
                {/* <Field
                  id={"ssocCode"}
                  name={"ssocCode"}
                  label="SSOC"
                  validator={requiredValidator}
                  component={FormInput}
                /> */}
              </FormRow>
              <FormRow>
                <Field
                  id={"highestQualification"}
                  name={"highestQualification"}
                  label="Highest Qualification"
                  data={HIGHEST_EDUCATION_OPTIONS}
                  validator={requiredValidator}
                  component={FormDropDownList}
                />
              </FormRow>
            </Card>

            <div className="k-d-flex k-my-8 k-justify-content-center">
              <Button
                type={"submit"}
                text={"Next"}
                theme={"primary"}
                size={"large"}
                disabled={!formRenderProps.allowSubmit}
              />
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default StepContact;
