/* eslint-disable */
import * as React from "react";
import moment from "moment";

import {
  Field,
  FieldRenderProps,
  FieldWrapper,
} from "@progress/kendo-react-form";
import {
  Input,
  MaskedTextBox,
  NumericTextBox,
  Checkbox,
  ColorPicker,
  Switch,
  RadioGroup,
  Slider,
  SliderLabel,
  RangeSlider,
  TextArea,
  Rating,
} from "@progress/kendo-react-inputs";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  DateRangePicker,
  DateInput,
  Calendar,
  CalendarCell,
} from "@progress/kendo-react-dateinputs";
import {
  Label,
  Error,
  Hint as KendoHint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import {
  DropDownList,
  AutoComplete,
  MultiSelect,
  ComboBox,
  MultiColumnComboBox,
} from "@progress/kendo-react-dropdowns";
import Hint from "components/atoms/Hint.shared";
import {
  Button,
  Chip,
  ChipList,
  ChipProps,
} from "@progress/kendo-react-buttons";
import _ from "lodash";
import { BookingModeFlow } from "../../helpers/const";

interface columnsInterface {
  field: string;
  header: any;
  width: string;
}

const CustomedBookingChip = (props: ChipProps) => {
  return (
    <Chip
      {...props}
      style={{ backgroundColor: "rgba(21, 109, 213, 0.05)" }}
      value={props.dataItem.id}
    >
      <div>
        {moment(props.dataItem.startAt).format("HH:mm")} -{" "}
        {moment(props.dataItem.endAt).format("HH:mm")}
      </div>
    </Chip>
  );
};

export const FormInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>

      <div className={"k-form-field-wrap"}>
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
        {hint && <Hint text={hint} />}
      </div>
    </FieldWrapper>
  );
};

export const FormInput32 = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>

      <div className={"k-form-field-wrap"}>
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormRadioGroup = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    onClickClear,
    clearale,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <RadioGroup
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          valid={valid}
          disabled={disabled}
          ref={editorRef}
          {...others}
        />
      </div>
      {clearale && !!onClickClear && (
        <div>
          <span
            className="k-i-times k-icon k-mt-3 k-p-1 k-cursor-pointer"
            onClick={onClickClear}
          ></span>
        </div>
      )}
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <NumericTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    value,
    labelCheckbox,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Checkbox
          {...others}
          ariaDescribedBy={`${hintId} ${errorId}`}
          label={labelCheckbox || ""}
          labelOptional={optional}
          valid={valid}
          id={id}
          disabled={disabled}
          value={Boolean(value)}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormSwitch = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    optional,
    id,
    valid,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <Switch
        ref={editorRef}
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormMaskedTextBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <MaskedTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    clearale,
    onClickClear,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <TextArea
          valid={valid}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
      {clearale && !!onClickClear && (
        <div>
          <span
            className="k-i-times k-icon k-mt-3 k-p-1 k-cursor-pointer"
            onClick={onClickClear}
          ></span>
        </div>
      )}
    </FieldWrapper>
  );
};

export const FormRadioButton = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    optional,
    clearale,
    onClickClear,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <RadioGroup layout="horizontal" {...others} />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
      {clearale && !!onClickClear && (
        <div>
          <span
            className="k-i-times k-icon k-mt-3 k-p-1 k-cursor-pointer "
            onClick={onClickClear}
          ></span>
        </div>
      )}
    </FieldWrapper>
  );
};

export const FormColorPicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <ColorPicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormSlider = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    data,
    min,
    max,
    ...others
  } = fieldRenderProps;

  const editorRef = React.useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <Slider
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        min={min}
        max={max}
        {...others}
      >
        {data.map((value: any) => (
          <SliderLabel title={value} key={value} position={value}>
            {value.toString()}
          </SliderLabel>
        ))}
      </Slider>
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormRangeSlider = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    data,
    min,
    max,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label}
      </Label>
      <RangeSlider
        valid={valid}
        id={id}
        disabled={disabled}
        ariaDescribedBy={`${hintId} ${errorId}`}
        min={min}
        max={max}
        {...others}
      >
        {data.map((value: any) => {
          return (
            <SliderLabel key={value} position={value}>
              {value.toString()}
            </SliderLabel>
          );
        })}
      </RangeSlider>
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormRating = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <Rating
        valid={valid}
        id={id}
        disabled={disabled}
        ariaDescribedBy={`${hintId} ${errorId}`}
        {...others}
      />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormUpload = (fieldRenderProps: FieldRenderProps) => {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} optional={optional}>
        {label}
      </Label>
      <Upload
        id={id}
        autoUpload={false}
        showActionButtons={false}
        multiple={false}
        files={value}
        onAdd={onChangeHandler}
        onRemove={onRemoveHandler}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDropDownList = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    onClickClear,
    clearale,
    spaceRight,
    refreshBy,
    enableRefresh,
    toolTip,
    ...others
  } = fieldRenderProps;

  const editorRef = React.useRef<any>(null);
  const [value, setValue] = React.useState<any>();
  const [previousData, setPreviousData] = React.useState<any>();
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  React.useEffect(() => {
    others.data?.forEach((item: any) => {
      if (item.value === others.value) {
        setValue(item);
      }
    });

    if (others.correctOption) {
      if (others.data) {
        setPreviousData(others.data);
      }
      const optionIsEqual = others.data?.every((item: any) =>
        previousData?.some(
          (itemPrev: any) =>
            item.text == itemPrev.text && item.value == itemPrev.value
        )
      );
      if (value?.value && !optionIsEqual) {
        setValue(null);
        fieldRenderProps.onChange({
          value: null,
        });
      }
    }
  }, [others]);

  React.useEffect(() => {
    if (enableRefresh) {
      if (value?.value) {
        setValue(null);
        fieldRenderProps.onChange({
          value: null,
        });
      }
    }
  }, [refreshBy]);

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className="k-pos-relative"
      >
        {label}
        {toolTip ? toolTip() : <></>}
      </Label>

      <div className={"k-form-field-wrap"}>
        {typeof others.data?.[0] === "object" ? (
          <DropDownList
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            ref={editorRef}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
            data={others.data}
            value={value}
            onChange={(e) => {
              others.onChange(e);
              setValue(e.target.value);
            }}
          />
        ) : (
          <DropDownList
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            ref={editorRef}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
          />
        )}

        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}

        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}

        {clearale && !!onClickClear && (
          <div>
            <span
              className="k-i-times k-icon k-my-2 k-p-1 k-cursor-pointer k-field-clearer"
              onClick={onClickClear}
            ></span>
          </div>
        )}
      </div>

      {spaceRight && <div style={{ height: 5, width: 22 }}></div>}
    </FieldWrapper>
  );
};

export const FormDropDownListClearable = (
  fieldRenderProps: FieldRenderProps
) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    clearale,
    spaceRight,
    ...others
  } = fieldRenderProps;

  const editorRef = React.useRef<any>(null);
  const [value, setValue] = React.useState<any>();
  const [firstSetted, setFirstSetted] = React.useState<any>(true);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  React.useEffect(() => {
    others.data?.forEach((item: any) => {
      if (item.value === others.value) {
        if (firstSetted) {
          setValue(item);
          setFirstSetted(false);
        }
      }
    });
  }, [others]);

  const onClickClear = () => {
    setValue(null);
    fieldRenderProps.onChange({
      value: null,
    });
  };

  return (
    <FieldWrapper style={wrapperStyle} className="k-pos-relative">
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>

      <div className={"k-form-field-wrap"}>
        {typeof others.data?.[0] === "object" ? (
          <DropDownList
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            ref={editorRef}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
            data={others.data}
            value={value}
            onChange={(e) => {
              others.onChange(e);
              setValue(e.target.value);
            }}
          />
        ) : (
          <DropDownList
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            ref={editorRef}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
          />
        )}

        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}

        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
      {clearale && !!onClickClear && (
        <div className="form-dropdown-clearer">
          <span
            className="k-i-times k-icon k-my-2 k-p-1 k-cursor-pointer k-field-clearer"
            onClick={onClickClear}
          ></span>
        </div>
      )}
      {spaceRight && <div style={{ height: 5, width: 22 }}></div>}
    </FieldWrapper>
  );
};

export const FormAutoComplete = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <AutoComplete
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormComboBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <ComboBox
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormMultiColumnComboBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const columns: Array<columnsInterface> = [
    { field: "id", header: <span>header</span>, width: "100px" },
    { field: "name", header: "Name", width: "300px" },
    { field: "position", header: "Position", width: "300px" },
  ];

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <MultiColumnComboBox
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        columns={columns}
        textField={"name"}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    hasEventDays,
    clearale,
    onClickClear,
    loading,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const customCell = (props: any) => {
    let className = "";
    const key = props?.value
      ? moment(new Date(props.value)).format("DD/MM/YYYY")
      : null;
    if (key && hasEventDays?.includes(key)) {
      className = "has-event";
    }
    return <CalendarCell {...props} className={className} />;
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap k-pos-relative"}>
        <DatePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          min={new Date()}
          calendar={(props) => <Calendar {...props} cell={customCell} />}
          {...others}
        />
        {showHint && (
          <KendoHint id={hintId} direction={hintDirection}>
            {hint}
          </KendoHint>
        )}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
        {!loading && clearale && !!onClickClear && (
          <div>
            <span
              className="k-i-times k-icon k-my-2 k-p-1 k-field-clearer"
              onClick={onClickClear}
            ></span>
          </div>
        )}
        {loading === true && (
          <div className="k-loading-mask size-1-rem">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormDateTimePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <DateTimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormTimePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <TimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          format={{
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
          }}
          {...others}
        />
        {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export const FormDateInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DateInput
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDateRangePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DateRangePicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormFloatingNumericTextBox = (
  fieldRenderProps: FieldRenderProps
) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    value,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <FloatingLabel
        optional={optional}
        editorValue={value}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        label={label}
      >
        <NumericTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          value={value}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </FloatingLabel>
      {showHint && <KendoHint id={hintId}>{hint}</KendoHint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormChipList = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    data,
    spaceRight,
    chipKey,
    chipPropKey,
    isSelectedDate,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>

      <div key={chipPropKey} className="k-form-field-wrap">
        {data?.length > 0 ? (
          <ChipList
            data={data}
            selection="single"
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
            {...others}
          />
        ) : isSelectedDate ? (
          <p className="k-text-error" style={{ marginTop: 6, fontWeight: 500 }}>
            Slots not available
          </p>
        ) : (
          <p className="k-m-1">(Please select Date to display slots)</p>
        )}
      </div>
      {spaceRight && <div style={{ height: 5, width: 22 }}></div>}
    </FieldWrapper>
  );
};

export const FormChipAutoGroupList = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    data,
    spaceRight,
    chipKey,
    chipPropKey,
    isSelectedDate,
    bookingMode,
    renderKey,
    loading,
    selectedTouchpoint, // WCC | OTH
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";
  const [selectedKey, setSelectedKey] = React.useState<any>(null);

  const renderWorkshopFaceToFace = () => {
    // Group by address
    let availabilityByTP = _.groupBy(data, "address");
    let displayData = [];

    for (const [address, items] of Object.entries(availabilityByTP)) {
      displayData.push({
        address,
        items: helperGroupByTime(items),
      });
    }
    const emptyAddressObj = displayData.filter((e) => e.address === "");
    const nonEmptyAddressObj = displayData.filter((e) => e.address);
    displayData = _.sortBy(nonEmptyAddressObj, "address");
    displayData = [...displayData, ...emptyAddressObj];

    return displayData.map(({ address, items }, index) => (
      <div className="k-py-3 k-mb-3 chiplist-group">
        <p className="k-mt-0 k-mb-1 k-ml-1">
          {address
            ? `#${index + 1} Location: ${address}`
            : `#${index + 1} Location: (Workshop not specify address)`}
          <br />
        </p>
        <ChipListAutoRefresh
          data={items}
          selection="single"
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={`${index}`}
          chipKey={index}
          selectedKey={selectedKey}
          disabled={disabled}
          {...others}
          onChange={(item: any) => {
            setSelectedKey(index);
            const timeRange = items.find((e) => e.value === item.value);
            others.onChangeSelection(timeRange);
          }}
        />
      </div>
    ));
  };

  const renderWorkshopVirtual = () => {
    const items = helperGroupByTime(data);
    return (
      <div className="k-py-3 k-mb-3 chiplist-group">
        <p className="k-mt-0 k-mb-1 k-ml-1">(Virtual Workshop)</p>
        <ChipListAutoRefresh
          data={items}
          selection="single"
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={`${id}`}
          chipKey={0}
          selectedKey={selectedKey}
          disabled={disabled}
          {...others}
          onChange={(item: any) => {
            const timeRange = items.find((e) => e.value === item.value);
            others.onChangeSelection(timeRange);
          }}
        />
      </div>
    );
  };

  const renderWorkshop = () => {
    return bookingMode?.bookingMode === "F2F"
      ? renderWorkshopFaceToFace()
      : renderWorkshopVirtual();
  };

  const renderCoachingFaceToFace = () => {
    // Group by touchpoint
    let availabilityByTP = _.groupBy(data, "touchpointLocationName");
    let groupDisplayData = [];

    for (const [touchpointLocationName, items] of Object.entries(availabilityByTP)) {
      groupDisplayData.push({
        touchpointLocationName,
        items: helperGroupByTime(items),
      });
    }

    const sortedDisplayData = _.sortBy(groupDisplayData, "touchpointLocationName");

    const currentTpData = sortedDisplayData.filter(
      (e: any) => e.touchpointLocationName === selectedTouchpoint
    );
    const anotherTpData = sortedDisplayData.filter(
      (e: any) => e.touchpointLocationName !== selectedTouchpoint
    );

    const displayData = currentTpData.concat(anotherTpData);

    return displayData.map(({ touchpointLocationName, items }, index) => (
      <div className="k-mb-4 chiplist-group">
        <p className="k-my-0 k-ml-1 k-font-weight-bold">
          Available at {touchpointLocationName}
          <br />
        </p>
        {/* props.selectedKey != props.chipKey */}
        <ChipListAutoRefresh
          data={items}
          selection="single"
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={`${index}`}
          chipKey={index}
          selectedKey={selectedKey}
          disabled={disabled}
          {...others}
          onChange={(item: any) => {
            setSelectedKey(index);
            const timeRange = items.find((e) => e.value === item.value);
            others.onChangeSelection(timeRange);
          }}
        />
      </div>
    ));
  };

  const renderCoachingVirtual = () => {
    const items = helperGroupByTime(data);

    return (
      <div className="k-py-3 k-mb-3 chiplist-group">
        <p className="k-my-0 k-ml-1 k-font-weight-bold">
          Available
          <br />
        </p>
        <ChipListAutoRefresh
          data={items}
          selection="single"
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={`${id}`}
          chipKey={0}
          selectedKey={selectedKey}
          disabled={disabled}
          {...others}
          onChange={(item: any) => {
            const timeRange = items.find((e) => e.value === item.value);
            others.onChangeSelection(timeRange);
          }}
        />
      </div>
    );
  };

  const renderCoaching = () => {
    return bookingMode?.bookingMode === "F2F"
      ? renderCoachingFaceToFace()
      : renderCoachingVirtual();
  };

  const helperGroupByTime = (slotItems: any[]) => {
    let availabilitiTimed = _.groupBy(slotItems, "text");
    let finalData = [];

    for (const [text, timeItem] of Object.entries(availabilitiTimed)) {
      finalData.push({
        text,
        value: timeItem?.[0].day + " " + text,
        ids: timeItem?.map((e) => e.id),
        coachInfo: timeItem?.map((e) => e.coach),
      });
    }
    finalData = _.sortBy(finalData, "text");
    return finalData;
  };

  if (!bookingMode) return <></>;

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>

      <div key={chipPropKey} className="k-form-field-wrap">
        <div className="chiplist-container k-pos-relative">
          {data?.length > 0 ? (
            bookingMode?.bookingType?.label === BookingModeFlow.Coaching ? (
              renderCoaching()
            ) : (
              renderWorkshop()
            )
          ) : (
            <div className="k-py-3 chiplist-group">
              {isSelectedDate ? (
                loading ? (
                  <p className="k-text-center k-my-8"></p>
                ) : (
                  <p
                    className="k-text-error"
                    style={{ marginTop: 6, marginLeft: 6, fontWeight: 500 }}
                  >
                    Slots not available, please choose another date.
                  </p>
                )
              ) : (
                <p className="k-m-1">(Please select Date to display slots)</p>
              )}
            </div>
          )}
          {loading === true && (
            <div className="k-loading-mask size-2-rem">
              <span className="k-loading-text">Loading</span>
              <div className="k-loading-image"></div>
              <div className="k-loading-color"></div>
            </div>
          )}
        </div>
      </div>
      {spaceRight && <div style={{ height: 5, width: 22 }}></div>}
    </FieldWrapper>
  );
};

const ChipListAutoRefresh = (props: any) => {
  const [renderKey, setRenderKey] = React.useState(0);

  React.useEffect(() => {
    if (props.selectedKey > -1 && props.selectedKey != props.chipKey) {
      setRenderKey(renderKey + 1);
    }
  }, [props.selectedKey]);

  return (
    <div key={renderKey}>
      <ChipList {...props} />
    </div>
  );
};
