import PageContainer from "components/layout/PageContainer.shared";
import OrganizationConfirmView from "components/page-section/booking/OrganizationConfirm.section";
import Header from "components/shared/Header.shared";
const OrganizationConfirm = () => {

  return (
    <div className="container">
      <Header
        title={"Confirm Proceed Booking"}
        subTitle={"Please confirm your action"}
      />
      <PageContainer>{<OrganizationConfirmView />}</PageContainer>
    </div>
  );
};

export default OrganizationConfirm;
