export const SET_BOOKING_DATA = "SET_BOOKING_DATA";
export const SET_AMENDING_BOOKING_DATA = "SET_AMENDING_BOOKING_DATA";
export const SET_BOOKINGS_DATA = "SET_BOOKINGS_DATA";
export const REMOVE_BOOKING_DATA = "REMOVE_BOOKING_DATA";

export const setBookingData = ({
  field,
  data,
}: {
  field: string;
  data: any;
}) => ({
  type: SET_BOOKING_DATA,
  payload: { field, data },
});

export const removeBookingData = () => ({
  type: REMOVE_BOOKING_DATA,
});

export const setBookingsData = (data: any) => ({
  type: SET_BOOKINGS_DATA,
  payload: data,
});

export const setAmendingBookingData = (data: any) => ({
  type: SET_AMENDING_BOOKING_DATA,
  payload: data,
});

