import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore } from "redux";
import App from "./App";
import "./index.scss";
import reducer from "./store/reducer";
import reportWebVitals from "./reportWebVitals";
import ToastContainer from "components/atoms/Toast.shared";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_ENV === 'development' ? 1.0 : 0.1,
  debug: false,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = createStore(reducer);

root.render(
  <Provider store={store}>
    <App />
    <ToastContainer/>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
