type FormRowProps = {
  children: any;
  onlyOne?: boolean;
};

const FormRow = ({ children, onlyOne }: FormRowProps) => {
  return (
    <div className={`k-row-container ${onlyOne ? "one-item" : ""}`}>
      {children}
    </div>
  );
};

export default FormRow;
