import { axiosInstance } from "helpers/axios";

const bookingAPI = {
  list() {
    return axiosInstance.get(`/v2/booking/`);
  },

  getOne(id: string) {
    return axiosInstance.get(`/v2/booking/${id}`);
  },

  create(payload: any) {
    return axiosInstance.post(`/v2/booking/`, payload);
  },

  update(id: any, payload: any) {
    return axiosInstance.put(`/v2/booking/${id}`, payload);
  },

  cancel(id: any) {
    return axiosInstance.delete(`/v2/booking/${id}`);
  },

  listOccupation(skip: number, limit: number, keyword?: string) {
    return axiosInstance.get(`/v2/booking/occupations`,{
      params:{
        skip,
        limit,
        keyword
      }
    });
  },

};

export default bookingAPI;
