type TextLabelInputProps = {
  children?: any;
  label?: string;
};

const TextLabelInput = ({ children, label }: TextLabelInputProps) => {
  return (
    <div className="fs-14 k-mb-4 k-text-light">
      <p className="k-font-weight-bold k-mb-2 k-ml-2 k-mt-0 k-text-base">{label}</p>
      <div className="text-label-input">{children}</div>
    </div>
  );
};

export default TextLabelInput;
