import { FormRenderProps } from "@progress/kendo-react-form";
import bookingAPI from "api/v1/Booking.api";
import coachAvailabilityAPI from "api/v1/CoachAvailability.api";
import customerUserAPI from "api/v1/CustomerUser.api";
import { useCustomNavigate, useToast } from "helpers/hook";
import { hasUserInfo } from "helpers/model";
import _ from "lodash";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setAmendingBookingData } from "store/actions/booking";
import { ROUTE_PATH } from "types/route-path";

export enum StatusForm {
  origin = "origin",
  editting = "editting",
  locked = "locked",
  required = "required",
}

type InitFormStatus = {
  touchpoint: StatusForm;
  bookingDate: StatusForm;
  bookingName: StatusForm;
  oic: StatusForm;
  remark: StatusForm;
  attendance: StatusForm;
};

const initialFormStatus: InitFormStatus = {
  touchpoint: StatusForm.origin,
  bookingDate: StatusForm.origin,
  bookingName: StatusForm.origin,
  oic: StatusForm.origin,
  remark: StatusForm.origin,
  attendance: StatusForm.origin,
};

const INIT_SELECT_DATE_OIC = {
  originCAId: null,
  selectingOIC: null,
  availableSlotOption: [],
  availableOICOption: [],
};

const useBookingAmendFunction = () => {
  let { id } = useParams<any>();
  const editorRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { navigateWithQueryString } = useCustomNavigate();

  const booking = useSelector((state: any) => state.amendingBooking);
  const guid = useSelector((state: any) => state.guid);
  const [recommendTouchpoint, setRecommendTouchpoint] = useState<any>("");
  const [selectedBookingModeCode, setSelectedBookingModeCode] =
    useState<string>("");
  const [touchpointMapBookingMode, setTouchpointMapBookingMode] = useState<
    any[]
  >([]);
  const [bookingModeCodes, setBookingModeCodes] = useState<any[]>([]);
  const [coachAvailabilityId, setCoachAvailabilityId] = useState<string>("");
  const [eligibleStatus, setEligibleStatus] = useState<any | null>(null);
  const [selectedTouchpoint, setSelectedTouchpoint] = useState<string[]>([]);
  const [editStatus, setEditStatus] = useState(initialFormStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDayHasSlot, setLoadingDayHasSlot] = useState<boolean>(false);
  const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [dayHasAvaibilities, setDayHasAvaibilities] = useState<any[]>([]);
  const [bookingDate, setBookingDate] = useState<any>("");
  const [isSlotWaitingList, setIsSlotWaitingList] = useState<boolean>(false);
  const [selectDateOIC, setSelectDateOIC] = useState<{
    originCAId: any;
    selectingOIC: any;
    availableSlotOption: any[];
    availableOICOption: any[];
  }>(INIT_SELECT_DATE_OIC);
  const [pendingData, setPendingData] = useState<any>(null);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const [isShowConfirmCancel, setIsShowConfirmCancel] =
    useState<boolean>(false);

  const { toastSuccess, toastError, toastWarn } = useToast();
  const userCC4Info = useSelector((state: any) => state.userCC4Info);
  const getBooking = async () => {
    try {
      setIsLoading(true);
      const response = await bookingAPI.getOne(id);
      const bookingResponse = response.data;
      setIsLoading(false);
      if (bookingResponse?.coachAvailability?.touchpoint?.name) {
        setSelectedTouchpoint([
          bookingResponse.coachAvailability.touchpoint.name,
        ]);
      }
      setSelectedBookingModeCode(bookingResponse.bookingMode.code);
      setCoachAvailabilityId(bookingResponse.coachAvailabilityId);
      setBookingDate(new Date(bookingResponse.start));
      dispatch(setAmendingBookingData(bookingResponse));
    } catch (error) {
      console.log(error);
      dispatch(setAmendingBookingData(null));
    }
  };

  const getBookingMode = async () => {
    const touchpointName = selectedTouchpoint[0];
    if (touchpointName) {
      const dataPreload = touchpointMapBookingMode.find(
        (e: any) => e.touchpoint.name === touchpointName
      );
      if (dataPreload && dataPreload.bookingModes) {
        setBookingModeCodes(dataPreload.bookingModes);
      }
    }
  };

  const getPrepareBookingData = async () => {
    try {
      setIsLoading(true);
      const { data } = await customerUserAPI.prepareAmendData(
        guid,
        booking.id,
        hasUserInfo(userCC4Info)
      );
      if (data.recommend_touchpoint) {
        setRecommendTouchpoint(data.recommend_touchpoint.name);
      }
      setEligibleStatus(data.eligible_status);
      setTouchpointMapBookingMode(data.touchpoint_booking_modes);
      setIsLoading(false);
      return data;
    } catch (error: any) {
      setIsLoading(false);
      toastError(error);
    }
  };

  const getDayHasCoachAvailability = async () => {
    setLoadingDayHasSlot(true);
    const { data } = await coachAvailabilityAPI.getDayHasAvailabilities(
      selectedBookingModeCode,
      selectedTouchpoint,
      true,
      hasUserInfo(userCC4Info),
      booking.coachAvailabilityId,
      eligibleStatus?.cmpEligibleType || null
    );
    setDayHasAvaibilities(data);
    setLoadingDayHasSlot(false);
  };

  const getCoachAvailabilities = async () => {
    setLoadingSlots(true);
    const { data } = await coachAvailabilityAPI.getAvailabilities(
      bookingDate,
      selectedBookingModeCode,
      selectedTouchpoint,
      true,
      hasUserInfo(userCC4Info),
      booking.coachAvailabilityId,
      eligibleStatus?.cmpEligibleType || null
    );
    // group by time to remove dupplicate
    const timeMapped = data.map((m: any) => ({
      id: m.id,
      coach: {
        coachAvailabilityId: m.id,
        isSlotWaitingList: m.isSlotWaitingList,
        oicId: m.coach.id + (m.subCoachId ? "-" + m.subCoachId : ""),
        subCoachId: m.subCoachId,
        subCoachFirstName: m.subCoach?.firstName,
        subCoachLastName: m.subCoach?.lastName,
        subCoachPosition: m.subCoach?.position,
        subCoachDefaultTouchpointName: m.subCoach?.defaultTouchpointName,
        ...(m.coach || {}),
      },
      day: `${moment(m.startAt).format("DD/MM/yyyy")}`,
      text: `${moment(m.startAt).format("HH:mm")} - ${moment(m.endAt).format(
        "HH:mm"
      )}`,
      address: m.address || "",
      touchpointId: m.touchpointId,
      touchpointName: m.touchpoint?.name || "",
      touchpointLocationName: m.touchpoint?.locationName || "",
    }));

    setAvailabilities(timeMapped);
    setLoadingSlots(false);
  };

  const onDateChange = async ({ value }: { value: string }) => {
    setBookingDate(value);
  };

  const clearDayHasCoachAvailability = async () => {
    setLoadingDayHasSlot(false);
    setDayHasAvaibilities([]);
  };

  const clearCoachAvailability = async () => {
    setLoadingSlots(false);
    setAvailabilities([]);
  };

  const refreshSlotSelection = (formRenderProps: any) => {
    formRenderProps.onChange("coachAvailabilityId", { value: null });
    setCoachAvailabilityId("");
  };

  const shouldShowText = (field: keyof InitFormStatus) => {
    return (
      editStatus[field] === StatusForm.origin ||
      editStatus[field] === StatusForm.locked
    );
  };

  const editable = (field: keyof InitFormStatus) => {
    return editStatus[field] === StatusForm.origin;
  };

  const clearale = (field: keyof InitFormStatus) => {
    return editStatus[field] === StatusForm.editting;
  };

  const currentBookingMode = () => {
    return bookingModeCodes.find(
      (e: any) => e.code === selectedBookingModeCode
    );
  };

  const onBookingModeCodeChange = async ({ value }: { value: any }) => {
    if (value?.value) {
      setSelectedBookingModeCode(value.value);
    } else {
      setSelectedBookingModeCode("");
    }
  };

  const onChangeSlot = (timeRange: any) => {
    if (timeRange) {
      const coachAvailabilityId =
        timeRange.ids[Math.floor(Math.random() * timeRange.ids.length)];
      setCoachAvailabilityId(coachAvailabilityId);

      const selectableOIC = _.unionBy(timeRange?.coachInfo, "oicId");
      const selectingOIC = timeRange?.coachInfo.find(
        (e: any) => e.coachAvailabilityId === coachAvailabilityId
      );

      if ((selectingOIC as any).isSlotWaitingList) {
        toastWarn(
          "Selecting a full capacity slot, booking will be in waiting list"
        );
        setIsSlotWaitingList(true);
      } else {
        setIsSlotWaitingList(false);
      }

      setSelectDateOIC({
        originCAId: coachAvailabilityId,
        selectingOIC: selectingOIC,
        availableOICOption: selectableOIC,
        availableSlotOption: timeRange?.coachInfo,
      });
      // editorRef?.current.onChange("changeDateCoachId", {
      //   // tddo fix here
      //   value: {
      //     value: 0,
      //   },
      // });
    }
  };

  const submitable = (formRenderProps: FormRenderProps) => {
    const customerResponse = booking.customerRecords?.[0];
    return !!(
      !booking?.bookingModeBlockPublicAmend &&
      selectedBookingModeCode &&
      ((coachAvailabilityId &&
        coachAvailabilityId !== booking?.coachAvailabilityId) ||
        customerResponse.emailAddress !==
          formRenderProps.valueGetter("emailAddress") ||
        customerResponse.mobileNumber !==
          formRenderProps.valueGetter("mobileNumber"))
    );
  };

  const startEditFieldAndLockAnother = (fieldEnable: keyof InitFormStatus) => {
    const affectedList: (keyof InitFormStatus)[] = [
      "bookingDate",
      "bookingName",
      "oic",
      "touchpoint",
    ];
    const newStatus = {
      ...editStatus,
    };
    newStatus[fieldEnable] = StatusForm.editting;
    affectedList
      .filter((e) => e != fieldEnable)
      .forEach((key: string) => {
        newStatus[key as keyof InitFormStatus] = StatusForm.locked;
      });
    setEditStatus(newStatus);
  };

  const stopEditFieldAndUnLockAnother = (fieldEnable: keyof InitFormStatus) => {
    const newStatus = {
      ...editStatus,
    };
    newStatus[fieldEnable] = StatusForm.origin;
    Object.keys(initialFormStatus).forEach((key: string) => {
      newStatus[key as keyof InitFormStatus] = StatusForm.origin;
    });
    setEditStatus(newStatus);
  };

  const handleClickCancel = () => {
    setIsShowConfirmCancel(true);
  };

  const handleClickBack = () => {
    navigateWithQueryString(ROUTE_PATH.HOME);
  };

  const handleAmendBooking = async (appointmentData: any) => {
    try {
      const updateBookingPayload: any = {
        editType: "other_field",
      };
      updateBookingPayload.coachAvailabilityId =
        coachAvailabilityId || booking.coachAvailabilityId;
      updateBookingPayload.bookingModeCode = selectedBookingModeCode;
      updateBookingPayload.customerEmail = appointmentData.emailAddress;
      updateBookingPayload.customerPhoneNumber = appointmentData.mobileNumber;
      setPendingData(updateBookingPayload);
      setIsShowConfirm(true);
    } catch (error) {
      setIsShowConfirm(false);
      toastError(error);
    }
  };

  const onConfirmAmend = async () => {
    try {
      const bookingRes = await bookingAPI.update(booking.id, pendingData);
      setPendingData(null);
      toastSuccess("Booking is successfully amended.");
      if (bookingRes?.data?.getMeetingLinkError) {
        toastError(bookingRes?.data?.getMeetingLinkError, {
          autoClose: 15000,
        });
      }
      navigateWithQueryString(ROUTE_PATH.HOME);
    } catch (error) {
      setPendingData(null);
      toastError(error);
    }
  };

  const onConfirmCancel = async () => {
    try {
      const bookingRes = await bookingAPI.cancel(booking.id);
      setPendingData(null);
      toastSuccess("Booking is successfully cancelled.");
      if (bookingRes?.data?.getMeetingLinkError) {
        toastError(bookingRes?.data?.getMeetingLinkError, {
          autoClose: 15000,
        });
      }
      navigateWithQueryString(ROUTE_PATH.HOME);
    } catch (error) {
      setPendingData(null);
      toastError(error);
    }
  };

  const initBookingFormData = useMemo(() => {
    if (booking) {
      const customerResponse = booking.customerRecords?.[0];
      return {
        touchpointName: booking.coachAvailability?.touchpoint?.name,
        anotherReason: booking.anotherReason,
        coachAvailabilityTimeValue: booking.coachAvailabilityId,
        bookingName: booking.bookingMode?.name,
        start: booking.start,
        end: booking.end,
        preferredName: customerResponse.preferredName,
        age: customerResponse.age,
        emailAddress: customerResponse.emailAddress,
        mobileNumber: customerResponse.mobileNumber,
        registeredPostalCode: customerResponse.registeredPostalCode,
        employmentStatus: customerResponse.employmentStatus,
        unemploymentReason: customerResponse.unemploymentReason,
        unemploymentDuration: customerResponse.unemploymentDuration,
        occupation: customerResponse.occupation,
        ssocCode: customerResponse.ssocCode,
        highestQualification: customerResponse.highestQualification,
        bookingModeCode: booking.bookingMode.code,
        bookingDate: new Date(booking.start),
      };
    }
  }, [booking]);

  return {
    selectedTouchpoint,
    eligibleStatus,
    isLoading,
    booking,
    guid,
    recommendTouchpoint,
    initBookingFormData,
    bookingModeCodes,
    bookingDate,
    dayHasAvaibilities,
    selectedBookingModeCode,
    loadingDayHasSlot,
    availabilities,
    loadingSlots,
    isShowConfirm,
    isShowConfirmCancel,
    setIsShowConfirmCancel,
    setIsShowConfirm,
    getBookingMode,
    getPrepareBookingData,
    getCoachAvailabilities,
    getDayHasCoachAvailability,
    clearDayHasCoachAvailability,
    clearCoachAvailability,
    refreshSlotSelection,
    onDateChange,
    getBooking,
    shouldShowText,
    clearale,
    currentBookingMode,
    editable,
    submitable,
    startEditFieldAndLockAnother,
    stopEditFieldAndUnLockAnother,
    onBookingModeCodeChange,
    onChangeSlot,
    handleAmendBooking,
    handleClickCancel,
    handleClickBack,
    onConfirmAmend,
    onConfirmCancel,
  };
};

export default useBookingAmendFunction;
