import moment from "moment"

export function timeStrToFormat(timeStr: string) {
    if (timeStr) {
        return moment(new Date(timeStr)).format("DD/MM/yyyy HH:mm")
    }
    return ""
}

export function timeStrToFormatDay(timeStr: string) {
    if (timeStr) {
        return moment(new Date(timeStr)).format("dddd DD MMM yyyy")
    }
    return ""
}

export function timeStrToFormatTime(timeStr: string) {
    if (timeStr) {
        return moment(new Date(timeStr)).format("HH:mm")
    }
    return ""
}