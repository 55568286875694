export enum AppointmentStatus {
  Available = "Available",
  Active = "Active",
  Cancelled = "Cancelled",
  Attended = "Attended",
  NotAttended = "Not Attended",
  Pending = "Pending",
  WaitingList = "Waiting List",
  WaitingListCancelled = "Waiting List - Cancelled",
}

export enum BookedStatus {
  Available = "Booking Available",
  Active = "Booking Confirmed",
  Cancelled = "Booking Cancelled",
  Attended = "Booking Attended",
  NotAttended = "Booking Not Attended",
  Pending = "Booking Pending",
}

export enum CMPEligibleType {
  PMET = "PMET",
  RNF = "RNF",
}

export enum CoachAvailabilityStatus {
  Available = "Available",
  NotAvailable = "Not Available",
}

export const CancellationReasons = [
  "Client is not ready to look for a job at the moment",
  "Client to make own appt",
  "Client prefers to do his/her own job search and does not require assistance at the moment",
  "Client has found a job",
  "Others (Please specify)",
];

export const NotAttendanceReasons = [
  "Client did not pick up, client hung up, client did not join video meeting/turn up at centre",
  "Rescheduled",
  "Client has found a job",
  "Client prefers to self-help",
  "Clashes with Client’s schedule",
  "Client is not ready to look for job at the moment",
  "Link not working",
  "Others (Please specify)",
];

export enum RoleType {
  Administrator = "Administrator",
  StaffUser = "StaffUser",
  OrganizationUser = "OrganizationUser",
  OrganizationManager = "OrganizationManager",
  Coach = "Coach",
  CA_SCA = "CA/SCA",
  IngeusCoach = "Ingeus Coach",
  MaximusCoach = "Maximus Coach",
  E2iEApptStaff = "e2i e-appt Staff",
  SEU = "SEU",
  Counsellor = "Counsellor",
  POD_CMP_OIC = "POD CMP OIC",
  POD_Executive = "POD Executive",
  POD_Leads_OIC = "POD Leads OIC",
  eServ = "eServ",
  MSF_HQ = "MSF HQ",
  MSF_SSO_WSG = "MSF SSO (WSG)",
  MSF_SSO_e2i = "MSF SSO (e2i)",
}

export const CoachRole: any[] = [
  RoleType.Coach,
  RoleType.CA_SCA,
  RoleType.IngeusCoach,
  RoleType.MaximusCoach,
  RoleType.E2iEApptStaff,
  RoleType.SEU,
  RoleType.Counsellor,
];

export const MSFRole: any[] = [
  RoleType.MSF_HQ,
  RoleType.MSF_SSO_WSG,
  RoleType.MSF_SSO_e2i,
];

export enum CoachPosition {
  Coach = "Career Coach",
  CA_SCA = "CA/SCA",
  SEU = "SEU",
  Counsellor = "Counsellor",
}

export enum BookingModeMode {
  F2F = "F2F",
  Phone = "Phone",
  Virtual = "Virtual",
}

export enum BookingModeFlow {
  Coaching = "Coaching",
  Workshop = "Workshop",
}

export enum OrgSupport {
  WSG = 'WSG',
  INGEUS = 'INGEUS',
  AKG = 'AKG',
  e2i = 'e2i',
}

export enum OrgId {
  WSG = 1,
  INGEUS = 2,
  AKG = 3,
  e2i = 4,
}

export const OICTypeByRole: any = {
  [RoleType.Coach]: CoachPosition.Coach,
  [RoleType.IngeusCoach]: CoachPosition.Coach,
  [RoleType.MaximusCoach]: CoachPosition.Coach,
  [RoleType.CA_SCA]: CoachPosition.CA_SCA,
  [RoleType.E2iEApptStaff]: CoachPosition.Coach,
  [RoleType.SEU]: CoachPosition.SEU,
  [RoleType.Counsellor]: CoachPosition.Counsellor,
};

export const LOGOUT_THRESHOLD = 1000 * 60 * 20;

export const TPSpecialValue = {
  all_wsg: "all_wsg",
  all: "all",
};
