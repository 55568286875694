import axios from "axios";

export const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const axiosInstance = axios.create({
  baseURL,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token && config && config.headers) {
    config.headers.Authorization = `Public ${token}`;
  }
  return config;
});
