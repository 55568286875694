import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import Button from "components/atoms/Button.shared";
import Hint from "components/atoms/Hint.shared";
import TextLabelInput from "components/atoms/TextLabelInput.shared";
import {
  FormChipAutoGroupList,
  FormDatePicker,
  FormDropDownList,
  FormInput,
} from "components/form/FormInputs";
import Card from "components/layout/Card.shared";
import FormRow from "components/layout/FormRow.shared";
import { emailValidator, isUnemployed, requiredStrValidator, requiredValidator } from "helpers/validator";
import useBookingAmendFunction from "hooks/booking-amend.hook";
import { useEffect, useRef } from "react";
import {
  AGE_OPTIONS,
  HIGHEST_EDUCATION_OPTIONS,
  UNEMPLOYMENT_DURATION_OPTIONS,
} from "types/constants";

type AmendBookingProps = {};

const AmendBooking = ({ }: AmendBookingProps) => {
  const editorRef = useRef<any>(null);

  const {
    guid,
    initBookingFormData: initData,
    bookingModeCodes,
    recommendTouchpoint,
    selectedBookingModeCode,
    selectedTouchpoint,
    eligibleStatus,
    bookingDate,
    dayHasAvaibilities,
    loadingDayHasSlot,
    availabilities,
    loadingSlots,
    isShowConfirm,
    isShowConfirmCancel,
    shouldShowText,
    editable,
    submitable,
    clearale,
    onChangeSlot,
    onDateChange,
    refreshSlotSelection,
    currentBookingMode,
    startEditFieldAndLockAnother,
    stopEditFieldAndUnLockAnother,
    onBookingModeCodeChange,
    getPrepareBookingData,
    getBookingMode,
    getCoachAvailabilities,
    getDayHasCoachAvailability,
    clearDayHasCoachAvailability,
    clearCoachAvailability,
    handleAmendBooking,
    handleClickCancel,
    handleClickBack,
    setIsShowConfirm,
    getBooking,
    onConfirmAmend,
    onConfirmCancel,
    setIsShowConfirmCancel,
  } = useBookingAmendFunction();

  useEffect(() => {
    getBooking();
  }, []);

  useEffect(() => {
    if (guid && initData) {
      getPrepareBookingData();
    }
  }, [guid, initData]);

  useEffect(() => {
    if (editorRef && initData) {
      // workaround enable submit without change
      const currentValue = editorRef.current.valueGetter('preferredName')
      editorRef.current.onChange("preferredName", {
        value: currentValue || (initData.preferredName || ""),
      });
    }
  }, [editorRef, initData]);

  useEffect(() => {
    if (selectedTouchpoint?.length && eligibleStatus) {
      getBookingMode();
    }
  }, [selectedTouchpoint, eligibleStatus]);

  useEffect(() => {
    if (selectedBookingModeCode && selectedTouchpoint?.length) {
      getDayHasCoachAvailability();
    } else {
      clearDayHasCoachAvailability();
    }
  }, [selectedBookingModeCode, selectedTouchpoint]);

  useEffect(() => {
    if (bookingDate && selectedBookingModeCode && selectedTouchpoint?.length) {
      getCoachAvailabilities();
    } else {
      clearCoachAvailability();
    }
  }, [bookingDate, selectedBookingModeCode, selectedTouchpoint]);

  if (!initData) return <></>;

  return (
    <Form
      initialValues={initData}
      onSubmit={handleAmendBooking}
      ref={editorRef}
      render={(formRenderProps) => (
        <FormElement horizontal={true}>
          <div>
            <Card title="Information">
              <FormRow>
                <Field
                  id={"preferredName"}
                  name={"preferredName"}
                  label="Name"
                  component={FormInput}
                  disabled
                />
                <Field
                  id={"age"}
                  name={"age"}
                  label="Age (as of booking date, i.e today)"
                  data={AGE_OPTIONS}
                  component={FormDropDownList}
                  disabled
                />
              </FormRow>
              <FormRow>
                <Field
                  id={"emailAddress"}
                  name={"emailAddress"}
                  label="Email"
                  component={FormInput}
                  validator={emailValidator}
                  hint="We will send confirmation to your email and phone. Make sure to fill correctly"
                />
                <Field
                  id={"mobileNumber"}
                  name={"mobileNumber"}
                  label="Mobile"
                  validator={requiredStrValidator}
                  component={FormInput}
                />
              </FormRow>
              <FormRow>
                <Field
                  id={"registeredPostalCode"}
                  name={"registeredPostalCode"}
                  label="Postal Code"
                  component={FormInput}
                  disabled
                />
              </FormRow>
            </Card>
            <Card title="Employment Info" containerClass="k-mt-4">
              <FormRow>
                <Field
                  id={"employmentStatus"}
                  name={"employmentStatus"}
                  label="Employment Status"
                  component={FormInput}
                  disabled
                />
              </FormRow>
              {isUnemployed(formRenderProps) && (
                <FormRow>
                  <Field
                    id={"unemploymentReason"}
                    name={"unemploymentReason"}
                    label="Reason for Unemployment"
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id={"unemploymentDuration"}
                    name={"unemploymentDuration"}
                    label="Duration of Unemployment"
                    data={UNEMPLOYMENT_DURATION_OPTIONS}
                    component={FormDropDownList}
                    disabled
                  />
                </FormRow>
              )}
              <FormRow>
                <Field
                  id={"occupation"}
                  name={"occupation"}
                  label="Occupation"
                  component={FormInput}
                  disabled
                />
                {/* <Field
                  id={"ssocCode"}
                  name={"ssocCode"}
                  label="SSOC"
                  component={FormInput}
                  disabled
                /> */}
              </FormRow>
              <FormRow>
                <Field
                  id={"highestQualification"}
                  name={"highestQualification"}
                  label="Highest Qualification"
                  data={HIGHEST_EDUCATION_OPTIONS}
                  component={FormDropDownList}
                  disabled
                />
              </FormRow>
            </Card>
            <Card
              title="Booking Information"
              addon={
                <Hint text="Change of location is not allowed. If you need to change your appointment to another location, please cancel this appointment and make another new appointment" />
              }
            >
              <FormRow onlyOne>
                <Field
                  id={"bookingModeCode"}
                  name={"bookingModeCode"}
                  label={"Career Advisory Mode"}
                  component={FormDropDownList}
                  data={bookingModeCodes
                    // .filter((e) => e?.bookingTypeId === selectedBookingType)
                    .map((m: any) => ({
                      text: m.name,
                      value: m.code,
                    }))}
                  // disabled={
                  //   !bookingModeCodes.filter(
                  //     (e) => e?.bookingTypeId === selectedBookingType
                  //   ).length || !formRenderProps.valueGetter("customerName")
                  // }
                  onChange={(event: any) => {
                    onBookingModeCodeChange(event);
                    // refreshSlotSelection(formRenderProps);
                  }}
                  textField="text"
                  dataItemKey="value"
                  correctOption={true}
                  validator={requiredValidator}
                // toolTip={BookingNameTooltip}
                ></Field>
              </FormRow>
              <FormRow onlyOne>
                <Field
                  id="coachAvailabilityTime"
                  name="coachAvailabilityTimeValue"
                  label=""
                  component={FormChipAutoGroupList}
                  textField={"text"}
                  valueField={"value"}
                  dataItemKey={"value"}
                  data={availabilities}
                  bookingMode={currentBookingMode()}
                  isSelectedDate={!!bookingDate}
                  onChangeSelection={onChangeSlot}
                  chipPropKey={bookingDate?.toString() || "1"}
                  loading={loadingSlots}
                />
              </FormRow>
              <FormRow onlyOne>
                <TextLabelInput label="You will be served by an officer from">
                  <span>
                    {initData?.touchpointName}{" "}
                    {recommendTouchpoint === initData?.touchpointName ? (
                      <span className="k-text-primary">
                        (Recommended based on Postal Code)
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </TextLabelInput>
              </FormRow>
              <FormRow onlyOne>
                <Field
                  id={"bookingDate"}
                  name={"bookingDate"}
                  label="Date"
                  component={FormDatePicker}
                  format={"dd/MM/yyyy"}
                  onChange={(event: any) => {
                    onDateChange(event);
                    refreshSlotSelection(formRenderProps);
                  }}
                  hasEventDays={dayHasAvaibilities}
                  clearale={clearale("bookingDate")}
                  onClickClear={() => {
                    stopEditFieldAndUnLockAnother("bookingDate");
                    // setCoachAvailabilityId(initData.coachAvailabilityId);
                    // setSelectDateOIC(INIT_SELECT_DATE_OIC);
                  }}
                  loading={loadingDayHasSlot}
                />
              </FormRow>
            </Card>

            {isShowConfirm && (
              <Dialog
                title="Please Confirm"
                onClose={() => setIsShowConfirm(false)}
              >
                <p style={{ margin: "25px", textAlign: "center" }}>
                  {`Continue to amend this booking?`}
                </p>

                <DialogActionsBar>
                  <button
                    type={"button"}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base rounded-8"
                    onClick={() => setIsShowConfirm(false)}
                  >
                    Close
                  </button>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary rounded-8"
                    onClick={() => {
                      onConfirmAmend();
                      setIsShowConfirm(false);
                    }}
                  >
                    Confirm Amend
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}

            {isShowConfirmCancel && (
              <Dialog
                title="Please Confirm"
                onClose={() => setIsShowConfirmCancel(false)}
              >
                <p style={{ margin: "25px", textAlign: "center" }}>
                  {`Continue to cancel this booking?`}
                </p>

                <DialogActionsBar>
                  <button
                    type={"button"}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base rounded-8"
                    onClick={() => setIsShowConfirmCancel(false)}
                  >
                    Close
                  </button>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error rounded-8"
                    onClick={() => {
                      onConfirmCancel();
                      setIsShowConfirmCancel(false);
                    }}
                  >
                    Confirm Cancel
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}

            <div className="k-d-flex k-my-8 k-justify-content-center">
              <Button
                type={"submit"}
                text={"Back"}
                theme={"light"}
                size={"large"}
                onClick={handleClickBack}
                className={"k-mx-3"}
              />

              <Button
                type={"submit"}
                text={"Amend Booking"}
                theme={"primary"}
                size={"large"}
                disabled={!submitable(formRenderProps)}
                className={"k-mx-3"}
              />

              <Button
                type={"button"}
                text={"Cancel Booking"}
                theme={"primary"}
                size={"large"}
                onClick={handleClickCancel}
                className={"k-mx-3"}
              />
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default AmendBooking;
