import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import Button from "components/atoms/Button.shared";
import TextLabelInput from "components/atoms/TextLabelInput.shared";
import {
  FormCheckbox,
  FormChipAutoGroupList,
  FormDatePicker,
  FormDropDownList,
  FormTextArea,
} from "components/form/FormInputs";
import Card from "components/layout/Card.shared";
import FormRow from "components/layout/FormRow.shared";
import { requiredStrValidator, requiredValidator } from "helpers/validator";
import useBookingCreateAppointmentFunction from "hooks/booking-create-appointment.hook";
import useBookingCreateFunction from "hooks/booking-create.hook";
import React, { useEffect, useState } from "react";
import {
  ANOTHER_REASON_ADHOC_TYPE,
  ANOTHER_TOUCHPOINT_REASON_OPTIONS,
} from "types/constants";
import { BookingStep } from "types/types";
import LoadingOverlay from "../proxy/LoadingOverlay.section";

type StepAppointmentProps = {};

// eslint-disable-next-line
const StepAppointment = ({ }: StepAppointmentProps) => {
  const {
    customerGuid,
    bookingUserInfo,
    editorRef,
    loadingSlots,
    isLoading,
    bookingDate,
    loadingDayHasSlot,
    selectedBookingModeCode,
    selectedTouchpoint,
    coachAvailabilityId,
    recommendTouchpoint,
    eligibleStatus,

    availabilities,
    dayHasAvaibilities,
    bookingModeCodes,
    anotherTouchpointOptions,

    getBookingMode,
    getPrepareBookingData,
    getDayHasCoachAvailability,
    getCoachAvailabilities,
    currentBookingMode,

    clearCoachAvailability,
    clearDayHasCoachAvailability,
    refreshSlotSelection,

    handleAddBooking,
    onChangeSlot,
    onDateChange,
    onAnotherTouchpointChange,
    onBookingModeCodeChange,
  } = useBookingCreateAppointmentFunction();

  const { setStep } = useBookingCreateFunction();
  const [showingClickBackAlert, setShowingClickBackAlert] =
    useState<any>(false);

  const handleClickBack = () => {
    setShowingClickBackAlert(true);
  };

  const handleConfirmClickBack = () => {
    setStep(BookingStep.Contact);
  };

  const handleCancelClickBack = () => {
    setShowingClickBackAlert(false);
  };

  const renderBookingModeItem = (li: any, itemProps: any) => {
    const infoText = itemProps.dataItem?.info_text

    const itemChildren = (
      <span>
        {li.props.children} {infoText ? <span className="k-font-weight-light">- {infoText}</span> : ""}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

  useEffect(() => {
    if (selectedTouchpoint && eligibleStatus) {
      getBookingMode();
    }
  }, [selectedTouchpoint, eligibleStatus]);

  useEffect(() => {
    if (bookingUserInfo && customerGuid) {
      getPrepareBookingData(bookingUserInfo, customerGuid);
    }
  }, [bookingUserInfo, customerGuid]);

  useEffect(() => {
    if (selectedBookingModeCode && selectedTouchpoint?.length) {
      getDayHasCoachAvailability();
    } else {
      clearDayHasCoachAvailability();
    }
  }, [selectedBookingModeCode, selectedTouchpoint]);

  useEffect(() => {
    if (bookingDate && selectedBookingModeCode && selectedTouchpoint?.length) {
      getCoachAvailabilities();
    } else {
      clearCoachAvailability();
    }
  }, [bookingDate, selectedBookingModeCode, selectedTouchpoint]);

  return (
    <Form
      onSubmit={handleAddBooking}
      ref={editorRef}
      render={(formRenderProps) => (
        <FormElement horizontal={true}>
          <LoadingOverlay isShow={isLoading} message="Loading data ..." />
          <div>
            <Card title="Appointment Details">
              <FormRow onlyOne>
                <TextLabelInput label="You will be served by an officer from">
                  {recommendTouchpoint ? (
                    <span>
                      {recommendTouchpoint.locationName}{" "}
                      <span className="k-text-primary">
                        (Recommended based on Postal Code)
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </TextLabelInput>
              </FormRow>
              <FormRow onlyOne>
                <Field
                  id={"touchpoint"}
                  name={"touchpoint"}
                  textField="text"
                  dataItemKey="value"
                  component={FormDropDownList}
                  label={"Select another location to be served"}
                  data={anotherTouchpointOptions}
                  disabled={anotherTouchpointOptions?.length === 0}
                  onChange={(event: any) => {
                    onAnotherTouchpointChange(event);
                  }}
                // enableRefresh={true}
                // refreshBy={refreshTp}
                ></Field>
              </FormRow>
              {formRenderProps.valueGetter("touchpoint") &&
                formRenderProps.valueGetter("touchpoint")?.text !==
                "(not select)" && (
                  <FormRow>
                    <Field
                      id={"anotherReason"}
                      name={"anotherReason"}
                      label="Reason for selecting another location"
                      data={ANOTHER_TOUCHPOINT_REASON_OPTIONS}
                      validator={requiredValidator}
                      component={FormDropDownList}
                    />
                  </FormRow>
                )}
              {formRenderProps.valueGetter("anotherReason") ===
                ANOTHER_REASON_ADHOC_TYPE && (
                  <FormRow>
                    <Field
                      id={"anotherReasonText"}
                      name={"anotherReasonText"}
                      label="Other reason text"
                      validator={requiredStrValidator}
                      component={FormTextArea}
                    />
                  </FormRow>
                )}
              <FormRow>
                <Field
                  id={"bookingName"}
                  name={"bookingName"}
                  label={"Career Advisory Mode"}
                  component={FormDropDownList}
                  data={bookingModeCodes.map((m: any) => ({
                    text: m.name,
                    value: m.code,
                    info_text: m.infoText
                  }))}
                  onChange={(event: any) => {
                    onBookingModeCodeChange(event);
                    // refreshSlotSelection(formRenderProps);
                  }}
                  textField="text"
                  dataItemKey="value"
                  correctOption={true}
                  validator={requiredValidator}
                  itemRender={renderBookingModeItem}
                ></Field>
              </FormRow>
              <FormRow>
                <Field
                  id={"bookingDatePicker"}
                  name={"bookingDatePicker"}
                  label={"Date"}
                  component={FormDatePicker}
                  format={"dd/MM/yyyy"}
                  onChange={(event: any) => {
                    onDateChange(event);
                    refreshSlotSelection(formRenderProps);
                  }}
                  validator={requiredValidator}
                  hasEventDays={dayHasAvaibilities}
                  disabled={
                    !(selectedBookingModeCode && selectedTouchpoint?.length)
                  }
                  loading={loadingDayHasSlot}
                />
              </FormRow>
              <FormRow onlyOne>
                <div key={`${JSON.stringify(selectedTouchpoint)}`}>
                  <Field
                    id="coachAvailabilityId"
                    name="coachAvailabilityId"
                    label=""
                    component={FormChipAutoGroupList}
                    textField={"text"}
                    valueField={"value"}
                    dataItemKey={"value"}
                    data={availabilities}
                    bookingMode={currentBookingMode()}
                    isSelectedDate={!!bookingDate}
                    onChangeSelection={onChangeSlot}
                    selectedTouchpoint={selectedTouchpoint?.[0]}
                    chipPropKey={bookingDate?.toString() || "1"}
                    loading={loadingSlots}
                  />
                </div>
              </FormRow>
            </Card>

            <div className="k-mx-12">
              <Field
                id={"agreed"}
                name={"agreed"}
                validator={requiredValidator}
                labelCheckbox={
                  <label
                    htmlFor={"agreed"}
                    className={"k-font-weight-bold k-ml-2"}
                  >
                    I hereby declare that all the information given above is
                    true and correct to the best of my knowledge.
                  </label>
                }
                component={FormCheckbox}
              />
            </div>
            {showingClickBackAlert && (
              <Dialog
                title={"Announcements"}
                width={800}
                onClose={handleCancelClickBack}
              >
                <p className="k-mx-3 k-text-center content">
                  {
                    "You'll lose all entering information when back to previous step! Please careful!"
                  }
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base rounded-8"
                    onClick={handleCancelClickBack}
                    type={"button"}
                  >
                    Close
                  </button>
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error rounded-8"
                    onClick={handleConfirmClickBack}
                    type="button"
                  >
                    Confirm
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
            <div className="k-d-flex k-my-8 k-justify-content-center">
              <Button
                type={"button"}
                text={"Back"}
                theme={"light"}
                size={"large"}
                onClick={handleClickBack}
                className={"k-mr-6"}
              />
              <Button
                type={"submit"}
                text={"Confirm"}
                theme={"primary"}
                size={"large"}
                disabled={!formRenderProps.allowSubmit || !coachAvailabilityId}
              />
            </div>
          </div>
        </FormElement>
      )}
    />
  );
};

export default StepAppointment;
