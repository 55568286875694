import BlankSpace from "components/atoms/BlankSpace.shared";
import Button from "components/atoms/Button.shared";
import Hint from "components/atoms/Hint.shared";
import Card from "components/layout/Card.shared";
import InfoRow from "components/layout/InfoRow.shared";
import { BookingModeMode } from "helpers/const";
import { useCustomNavigate, useWindow } from "helpers/hook";
import useBookingCreateFunction from "hooks/booking-create.hook";
import moment from "moment";
import { forwardRef, useRef } from "react";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { removeBookingData } from "store/actions/booking";
import { EMPLOY_STATUS_OPTIONS } from "types/constants";
import { ROUTE_PATH } from "types/route-path";
import { BookingStep } from "types/types";

type StepConfirmationProps = {};

// eslint-disable-next-line
const StepConfirmation = ({ }: StepConfirmationProps) => {
  const { navigateWithQueryString } = useCustomNavigate();
  const { setStep } = useBookingCreateFunction();
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => { },
  });

  const handleClickBack = () => {
    setStep(BookingStep.Contact);
    dispatch(removeBookingData());
    navigateWithQueryString(ROUTE_PATH.HOME);
  };

  return (
    <div>
      <ContentDisplay ref={componentRef} />
      <div className="k-d-flex k-my-8 k-justify-content-center">
        <Button
          type={"button"}
          text={
            <div className="k-d-flex">
              <img src="/icons/printer.svg" className="h-26px k-mr-2" />
              <span>Print</span>
            </div>
          }
          theme={"primary"}
          size={"large"}
          disabled={false}
          onClick={handlePrint}
        />
        <Button
          type={"button"}
          text={"Back to Home"}
          theme={"light"}
          size={"large"}
          onClick={handleClickBack}
          className={"k-ml-6"}
        />
      </div>
    </div>
  );
};

const ContentDisplay = forwardRef((props: any, ref: any) => {
  const { booking } = useBookingCreateFunction();

  if (!booking || !booking?.bookingInfo)
    return <Card title="">Unable load booking info! Please try later!</Card>;

  const { bookingInfo } = booking;
  const customer = bookingInfo?.customerRecords?.[0];
  const touchpoint = bookingInfo?.coachAvailability?.touchpoint;
  const bookingName = bookingInfo?.bookingMode;
  const date = moment(new Date(bookingInfo.start)).format("dddd DD MMM yyyy");
  const time = moment(new Date(bookingInfo.start)).format("HH:mm");

  const remindMessage = () => {
    if (bookingName.bookingMode === BookingModeMode.F2F) {
      return `Please arrive at ${touchpoint.locationName} at ${touchpoint.locationAddress} at least 15 minutes before your appointment.`;
    } else if (bookingName.bookingMode === BookingModeMode.Virtual) {
      return "Please click on the virtual meeting link sent to you via email to connect with our officer 5 minutes prior to your appointment time.";
    } else if (bookingName.bookingMode === BookingModeMode.Phone) {
      return "Please standby at least 15 minutes earlier before your phone appointment and ensure that you are in a conducive environment. Our officer will contact you at the number that you have provided.";
    }
    return "";
  };

  return (
    <div ref={ref}>
      <Card title="Appointment Details">
        <BlankSpace height="20px" />
        <InfoRow title="Name" content={customer?.preferredName} />
        <InfoRow title="Email" content={customer?.emailAddress} />
        <InfoRow title="Mobile" content={customer?.mobileNumber} />
        <InfoRow title="Age" content={customer?.age} />
        <InfoRow title="Postal Code" content={customer?.registeredPostalCode} />
        <Hint text="Please read the confirmation email sent to you as it contains important information about your appointment." />
        <BlankSpace height="40px" />
        <InfoRow
          title="Employment Status"
          content={customer?.employmentStatus}
        />
        {
          customer?.employmentStatus === EMPLOY_STATUS_OPTIONS[2] && (
            <>
              <InfoRow
                title="Reason for Unemployment"
                content={customer?.unemploymentReason}
              />
              <InfoRow
                title="Duration of Unemployment"
                content={customer?.unemploymentDuration}
              />
            </>
          )
        }
        <InfoRow title="Occupation" content={customer?.occupation} />
        <InfoRow
          title="Highest Qualification"
          content={customer?.highestQualification}
        />

        <BlankSpace height="40px" />

        <InfoRow title="Career Advisory Mode" content={bookingName?.name} />
        <InfoRow
          title="Appointment Ref No"
          content={bookingInfo?.referenceNumber}
        />
        <InfoRow title="You will be served by an officer from" content={touchpoint?.name} />
        <InfoRow title="Date" content={date} />
        <InfoRow title="Time" content={time} />
        <BlankSpace height="20px" />
        <Hint text={remindMessage()} />
        <BlankSpace height="20px" />
      </Card>
    </div>
  );
});

export default StepConfirmation;
