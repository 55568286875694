import { Loader } from "@progress/kendo-react-indicators";

const LoadingOverlay = ({
  isShow,
  message,
}: {
  isShow?: boolean;
  message?: string;
}) => {
  return isShow ? (
    <div className="page-loading-overlay">
      <div className="k-d-flex k-flex-column k-align-items-center">
        <Loader size="large" type={"converging-spinner"} />
        {message && <h5 className="fs-16">{message}</h5>}
      </div>
    </div>
  ) : (
    <></>
  )
};

export default LoadingOverlay;
