import CreateBookingPage from "pages/CreateBooking.page";
import EditBookingPage from "pages/EditBooking.page";
import InvalidTokenPage from "pages/errors/InvalidToken.page";
import NotFoundPage from "pages/errors/NotFound.page";
import OrganizationVerifyPage from "pages/errors/OrganizationConfirm.page";
import UnableProcessPage from "pages/errors/UnableProcess.page";
import * as React from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { Route } from "react-router";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTE_PATH } from "types/route-path";
import "./App.scss";
import AuthRoute from "./components/shared/AuthRoute.shared";
import HomePage from "./pages/HomePage.page";
import AutoClosePage from "pages/errors/AutoClose.page";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <div className="App">
          <Switch>
            <AuthRoute exact={true} path={ROUTE_PATH.HOME} type="private">
              <HomePage />
            </AuthRoute>
            <AuthRoute
              exact={true}
              path={ROUTE_PATH.CREATE_BOOKING}
              type="private"
            >
              <CreateBookingPage />
            </AuthRoute>
            <AuthRoute
              exact={true}
              path={ROUTE_PATH.EDIT_BOOKING}
              type="private"
            >
              <EditBookingPage />
            </AuthRoute>
            <AuthRoute
              exact={true}
              path={ROUTE_PATH.UNABLE_PROCESS}
              type="private"
            >
              <UnableProcessPage />
            </AuthRoute>
            <AuthRoute
              exact={true}
              path={ROUTE_PATH.CONFIRM_ORGANIZATION}
              type="private"
            >
              <OrganizationVerifyPage />
            </AuthRoute>
            <AuthRoute exact={true} path={ROUTE_PATH.INVALID_GUID} type="guest">
              <InvalidTokenPage />
            </AuthRoute>
            <AuthRoute exact={true} path={'/require_close'} type="guest">
              <AutoClosePage />
            </AuthRoute>
            <Route path={ROUTE_PATH.NOT_FOUND}>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
        <ToastContainer style={{ zIndex: 99999 }} />
      </Router>
    </React.Fragment>
  );
};

export default App;
