type InfoRowProps = {
  title: string;
  content: string;
};

const InfoRow = ({ title, content }: InfoRowProps) => {
  return (
    <div className={`k-info-container`}>
      <span className="info-title">{title}:</span>
      <span>{content}</span>
    </div>
  );
};

export default InfoRow;
