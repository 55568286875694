export const SET_TOKEN_AND_GUID = "SET_TOKEN_AND_GUID";

export const setTokenAndGUID = (payload: {
  token: string;
  guid: string;
  expiredAt: number;
  userInfo: any;
  userCC4Info?: any;
  trackingCode?: string;
}) => {
  return {
    type: SET_TOKEN_AND_GUID,
    payload,
  };
};
