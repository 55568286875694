import Header from "components/shared/Header.shared";

const AutoClosePage = () => {
  return (
    <div className="container">
      <Header
        title="Ended Session"
        subTitle="Session has expired, please close this tab manually"
      />
    </div>
  );
};

export default AutoClosePage;
