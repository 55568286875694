import PageContainer from "components/layout/PageContainer.shared";
import UnableProcess from "components/page-section/booking/UnableProcess.section";
import Header from "components/shared/Header.shared";
import useHomePageFunction from "hooks/home-page.hook";

const UnableProcessPage = () => {
  const { headerTitle } = useHomePageFunction();

  return (
    <div className="container">
      <Header title={headerTitle} subTitle={""} />
      <PageContainer>{<UnableProcess />}</PageContainer>
    </div>
  );
};

export default UnableProcessPage;
