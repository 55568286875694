import { useWindow } from "helpers/hook";
import { pad2 } from "helpers/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

type HintProps = {
  addon?: any;
};

const Timed = ({ addon }: HintProps) => {
  const expiredAtNum = useSelector((state: any) => state.expiredAt);
  const [timeStr, setTimeStr] = useState("");
  const { closeWindow } = useWindow();

  useEffect(() => {
    const interval = setInterval(() => {
      expiredStrCalc();
      checkAutoClosePage();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [expiredAtNum]);

  const expiredStrCalc = () => {
    if (!expiredAtNum) setTimeStr("");
    const diffInSecond = Math.round(expiredAtNum - Date.now() / 1000);
    const mins = Math.floor(diffInSecond / 60);
    const seconds = diffInSecond - mins * 60;
    setTimeStr(`${pad2(mins)}:${pad2(seconds)}`);
  };

  const checkAutoClosePage = () => {
    if (!expiredAtNum) return;
    const diffInSecond = Math.round(expiredAtNum - Date.now() / 1000);
    if (diffInSecond <= 0) {
      closeWindow();
    }
  };

  return (
    <div className="k-d-flex k-align-items-start fs-14 k-mt-2 k-text-light">
      <div>
        <img src="/icons/clock-outline.svg" className="h-26px k-mr-2" />
      </div>
      <div>
        <p className="k-mb-0 k-mt-0">
          Please complete your booking within {timeStr} mins{" "}
        </p>
        {addon ? addon : ""}
      </div>
    </div>
  );
};

export default Timed;
