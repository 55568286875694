type CardProps = {
  children: any;
  title: string;
  containerClass?: string;
  addon?: any;
};

const Card = ({ children, title, containerClass, addon }: CardProps) => {
  return (
    <div className={`k-card-container ${containerClass || ""}`}>
      <h4 className="k-padding-card card-title">{title}</h4>
      <div className="k-mx-12 k-mb-4">{addon ? addon : <></>}</div>
      <div className="k-padding-card card-content bg-white-50 rounded-16 k-py-4">
        {children}
      </div>
    </div>
  );
};

export default Card;
