export const EMPLOY_STATUS_OPTIONS = [
  "Employed",
  "Self-Employed",
  "Unemployed",
];

export const UNEMPLOYMENT_REASON_OPTIONS = [
  "Elderly Care",
  "End of Contract / Assignment",
  "Home-maker / Taking Care of Children, Family",
  "Resigned",
  "Retired",
  "Retrenched",
  "School Leaver",
  "Service Terminated",
  "Student / Undergoing Training",
  "Others",
];

export const UNEMPLOYMENT_DURATION_OPTIONS = [
  "< 3 Months",
  "3 Months or more",
];

export const HIGHEST_EDUCATION_OPTIONS = [
  "No Formal Qualification",
  "Kindergarten Education",
  "Primary Education without PSLE / PSPE or equivalent",
  "BEST 1 - 3",
  "Primary Education with PSLE / PSPE or equivalent",
  "BEST 4",
  "ESS/ES WPLN 1 - 2",
  "Secondary Education without GCE 'O' / 'N' Level or equivalent",
  "WISE 1 - 3",
  "Basic Vocational Certificate",
  "ESS/ES WPLN 4",
  "GCE 'N' Level",
  "GCE 'O' Level",
  "Nitec (Intermediate) / Certificate of Vocational Training / BCA Builder Certificate / NTC Grade 3 or equivalent",
  "ISC or equivalent",
  "ESS/ES WPLN 5 and above",
  "Certificates or qualifications equivalent to Secondary education",
  "GCE 'A' Level",
  "Nitec, Certificate in Office Skills, NTC Grade 2 or equivalent",
  "Higher Nitec",
  "Master Nitec or equivalent",
  "WSQ Certificate / Career Readiness Certificate (Operations Level) or equivalent",
  "WSQ Higher Certificate or equivalent",
  "WSQ Advanced Certificate or equivalent",
  "Other non-tertiary or general post-secondary qualifications / IB / High School Diploma",
  "Other post-secondary non-tertiary or vocational certificates",
  "Polytechnic Diploma",
  "Advanced / Specialist / Management / Graduate Diploma",
  "ITE diploma",
  "Diploma qualifications (e.g. NIE, SIM, LaSalle-SIA, NAFA)",
  "Professional Certifications",
  "WSQ diploma",
  "WSQ specialist diploma",
  "Other Advanced Diploma / Post-Diploma qualifications or equivalent n.e.c.",
  "Bachelor's or equivalent",
  "Postgraduate Diploma / NIE Postgraduate Diploma",
  "WSQ Graduate Certificate",
  "WSQ Graduate Diploma",
  "Master's Degree or equivalent",
  "Doctorate or equivalent",
  "Other Statement of Attainment, modular certificate or equivalent n.e.c.",
];

export const AGE_OPTIONS = ["30 years old and above", "Below 30 years old"];

export const ANOTHER_REASON_ADHOC_TYPE = "Others";

export const ANOTHER_TOUCHPOINT_REASON_OPTIONS = [
  "Availability of preferred appointment slot",
  "Distance-related (e.g. nearer to work place)",
  ANOTHER_REASON_ADHOC_TYPE,
];
