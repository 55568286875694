import authAPI from "api/v1/Auth.api";
import LoadingFullSite from "components/page-section/proxy/LoadingFullSite.section";
import { useCustomNavigate } from "helpers/hook";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from "react-router";
import { setTokenAndGUID } from "store/actions/auth";
import { ROUTE_PATH } from "types/route-path";

const AuthRoute = (props: RouteProps & { type: "guest" | "private" }) => {
  const { type } = props;
  const dispatch = useDispatch();
  const [token] = useSelector((state: any) => [state.token, state.guid]);

  const state = useSelector((state: any) => state);
  const { navigateWithQueryString } = useCustomNavigate();

  const history = useHistory();
  const location = useLocation();
  const tokenInUrl = new URLSearchParams(location.search).get("token");
  const trackingCodeInUrl = new URLSearchParams(location.search).get(
    "referral_code"
  );

  useEffect(() => {
    if (!token && tokenInUrl) {
      if (
        location.pathname !== "/" &&
        window.location.host !== "localhost:3000"
      ) {
        navigateWithQueryString(ROUTE_PATH.HOME);
      }
      verifyToken(tokenInUrl);
    }

    if (location && !tokenInUrl && type == "private") {
    }
  }, [location, tokenInUrl, token]);

  const verifyToken = async (tokenInUrl: string) => {
    try {
      const {
        data: { guid, exp, userInfo },
      } = await authAPI.verifyToken({ token: tokenInUrl });

      dispatch(
        setTokenAndGUID({
          token: tokenInUrl,
          guid,
          userInfo,
          userCC4Info: userInfo,
          expiredAt: exp,
          trackingCode: trackingCodeInUrl || "",
        })
      );
    } catch (error) {
      history.push(ROUTE_PATH.INVALID_GUID);
    }
  };

  if (type == "guest") {
    return <Route {...props} />;
  }

  if (!token) {
    return <LoadingFullSite message="Loading GUID Data ..." />;
  }

  // get token trong redux, lay token tu url
  // neu roi vao cac case loi -> trang invalid token

  // if (inactivityAndLogout()) {
  //   props.logout();
  // }

  // if (type === "guest" && isAuthUser) return <Redirect to="/booking-list" />;
  // else if (type === "private" && !isAuthUser) return <Redirect to="/login" />;

  return <Route {...props} />;
};

export default AuthRoute;
