import { Step, Stepper as KendoStepper } from "@progress/kendo-react-layout";
const items = [
  {
    label: "Contact and Employment info",
    number: "1",
  },
  {
    label: "Select Appointment",
    number: "2",
  },
  {
    label: "Confirmation",
    number: "3",
  },
];

const StepCustomItem = (props: any) => {
  return (
    <Step {...props}>
      <span className="k-step-indicator" aria-hidden="true">
        <span className="k-font-weight-bold">{props.number}</span>
      </span>

      <span className="k-step-label">
        <span className="k-step-text">{props.label}</span>
      </span>
    </Step>
  );
};

type StepperProps = {
  value: number;
};

const Stepper = ({ value }: StepperProps) => {
  return (
    <div className="k-mt-6">
      <KendoStepper value={value} items={items} item={StepCustomItem} />
    </div>
  );
};

export default Stepper;
