import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useToast } from "helpers/hook";
import { useSelector } from "react-redux";

type BlankSpaceProps = {};

const ToastContainer = ({}: BlankSpaceProps) => {
  const toast = useSelector((state: any) => state.toast);
  const { close } = useToast();

  const theme = () => {
    if (toast.type === "success") {
      return {
        bg: "success",
        text: "Success",
        image: (
          <img src="/images/success.png" style={{ width: 100, height: 100 }} />
        ),
      };
    } else if (toast.type === "error") {
      return {
        bg: "error",
        text: "Error",
        image: (
          <img src="/images/error.png" style={{ width: 100, height: 100 }} />
        ),
      };
    } else if (toast.type === "warning") {
      return {
        bg: "warning",
        text: "Warning",
        image: (
          <img src="/images/warning.png" style={{ width: 100, height: 100 }} />
        ),
      };
    } else {
      return {
        bg: "base",
        text: "Alert",
        image: <></>,
      };
    }
  };

  if (!toast || !toast.isShow) return <></>;

  const { bg, text, image } = theme();
  return (
    <Dialog title={""} className={`${text} toast-dialog`} onClose={close}>
      <p
        className={`k-font-weight-bold k-text-center k-mt-6 k-text-${bg}`}
        style={{ fontSize: 24 }}
      >
        {image}
      </p>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
          minWidth: 260,
          marginBottom: 10,
          fontWeight: "bold",
        }}
      >
        {toast.message}
      </p>

      <DialogActionsBar>
        <button
          className={`k-button k-button-md k-button-solid k-button-solid-base k-font-weight-bold k-px-10 k-rounded-md rounded-8`}
          onClick={close}
        >
          OK
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ToastContainer;
