import { SET_TOKEN_AND_GUID } from "./actions/auth";
import {
  REMOVE_BOOKING_DATA,
  SET_AMENDING_BOOKING_DATA,
  SET_BOOKINGS_DATA,
  SET_BOOKING_DATA,
} from "./actions/booking";
import { SET_TOAST_STATUS } from "./actions/toast";

const INIT_BOOKING_STATE = {
  step: 0,
  guid: null,
  userInfo: null,
  bookingInfo: null,
};

export const INIT_TOAST_STATE = {
  isShow: false,
  type: "success",
  message: "",
};

const INIT_STATE = {
  token: null,
  guid: null,
  expiredAt: null,
  userInfo: null,
  userCC4Info: null,
  booking: INIT_BOOKING_STATE,
  bookings: [],
  amendingBooking: null,
  trackingCode: "",
  toast: INIT_TOAST_STATE,
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SET_TOKEN_AND_GUID:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("trackingCode", action.payload.trackingCode);
      localStorage.setItem("guid", action.payload.guid);
      localStorage.setItem("expiredAt", action.payload.expiredAt);
      return {
        ...state,
        token: action.payload.token,
        guid: action.payload.guid,
        expiredAt: action.payload.expiredAt,
        userInfo: action.payload.userInfo,
        userCC4Info: action.payload.userCC4Info,
        trackingCode: action.payload.trackingCode,
      };
    case SET_BOOKING_DATA:
      return {
        ...state,
        booking: {
          ...state.booking,
          [action.payload.field]: action.payload.data,
        },
      };
    case SET_BOOKINGS_DATA:
      return {
        ...state,
        bookings: action.payload,
      };
    case SET_AMENDING_BOOKING_DATA:
      return {
        ...state,
        amendingBooking: action.payload,
      };
    case REMOVE_BOOKING_DATA:
      return { ...state, booking: INIT_BOOKING_STATE };
    case SET_TOAST_STATUS:
      return {
        ...state,
        toast: {
          ...state.toast,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
