import { useSelector } from "react-redux";

const TrackingCode = () => {
  const token = useSelector((state: any) => state.trackingCode);
  if (token) {
    return (
      <p className="k-pos-absolute k-my-0 k-mr-6 k-right">
        <span className="k-font-weight-bold">Referral Code:</span>{" "}
        <span>{token}</span>
      </p>
    );
  }
  return <></>;
};

export default TrackingCode;
