import { axiosInstance } from "helpers/axios";

const authAPI = {
  verifyToken(data: { token: string }) {
    return axiosInstance.post(`/v2/auth/verify-token`, data);
  },
  revokeToken() {
    return axiosInstance.get(`/v2/auth/revoke-token`);
  },
};

export default authAPI;
