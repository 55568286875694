import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastOptions } from "react-toastify";
import { setToastStatus } from "store/actions/toast";
import { errorMsg } from "./toast";
import { INIT_TOAST_STATE } from "store/reducer";
import authAPI from "api/v1/Auth.api";

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useCustomNavigate() {
  const history = useHistory();
  const location = useLocation();

  const navigateWithQueryString = (path: string) => {
    history.push(path + location.search);
  };

  return {
    navigateWithQueryString,
  };
}

export function useToast() {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setToastStatus(INIT_TOAST_STATE as any));
  };

  const autoClose = () => {
    const timer = setTimeout(() => {
      dispatch(setToastStatus(INIT_TOAST_STATE as any));
      clearTimeout(timer);
    }, 3000);
  };

  const toastSuccess = (
    message: string,
    options?: ToastOptions<{}> | undefined
  ) => {
    dispatch(
      setToastStatus({
        type: "success",
        message,
        isShow: true,
      })
    );
    autoClose();
  };

  const toastWarn = (
    message: string,
    options?: ToastOptions<{}> | undefined
  ) => {
    dispatch(
      setToastStatus({
        type: "warning",
        message,
        isShow: true,
      })
    );
    autoClose();
  };

  const toastError = (error: any, options?: ToastOptions<{}> | undefined) => {
    if (typeof error === "string") {
      dispatch(
        setToastStatus({
          type: "error",
          message: error,
          isShow: true,
        })
      );
      autoClose();
    } else {
      dispatch(
        setToastStatus({
          type: "error",
          message: errorMsg(error),
          isShow: true,
        })
      );
      autoClose();
    }
  };

  return { toastError, toastSuccess, toastWarn, close };
}

export function useWindow() {
  const closeWindow = async () => {
    await authAPI.revokeToken();
    localStorage.clear();
    window.open(`${window.location.origin}/require_close`, "_self");
    window.close();
  };
  return { closeWindow };
}
