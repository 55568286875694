export const SET_TOAST_STATUS = "SET_TOAST_STATUS";

export const setToastStatus = (payload: {
  type: "success" | "error" | "warning";
  message: string;
  isShow: boolean;
}) => {
  return {
    type: SET_TOAST_STATUS,
    payload,
  };
};
