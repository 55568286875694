import Button from "components/atoms/Button.shared";
import { useCustomNavigate, useToast } from "helpers/hook";
import useHomePageFunction from "hooks/home-page.hook";
import { useEffect } from "react";
import { ROUTE_PATH } from "types/route-path";

const KickStart = () => {
  const { navigateWithQueryString } = useCustomNavigate();
  const { userInfo, organizations, isCheckingL1, getAllOrganization } =
    useHomePageFunction();
  const { toastError } = useToast();

  useEffect(() => {
    getAllOrganization();
  }, []);

  useEffect(() => {
    if (userInfo && organizations.length) {
      const organization = lastedOrganization()
      if (organization) {
        localStorage.setItem('lastOrg', JSON.stringify(organization))
        navigateWithQueryString(ROUTE_PATH.UNABLE_PROCESS);
      }
    }
  }, [userInfo, organizations])

  const lastedOrganization = () => {
    if (!userInfo?.organizations || userInfo?.organizations?.length === 0)
      return false;
    const ABSOrgsName = organizations.map((e: any) => e.uenName);
    const inABSOrg = userInfo?.organizations?.filter((org: string) =>
      ABSOrgsName.includes(org)
    );
    if (inABSOrg?.[0]) {
      return organizations.find((e: any) => e.uenName === inABSOrg[0])
    }
    return null;
  };

  const onClickNext = async () => {
    try {
      navigateWithQueryString(ROUTE_PATH.CREATE_BOOKING);
    } catch (error) {
      toastError(error);
    }
  };
  return (
    <div className="kickstart-container">
      <div
        style={{
          width: "60%",
          minHeight: "50vh",
          marginTop: "3vh",
        }}
        className="bg-white-50 rounded-16 m-auto k-p-8 k-justify-content-center k-d-flex k-align-items-center"
      >
        <div className="k-text-center">
          <p className="k-text-justify k-mb-6">
            Welcome to the appointment booking system for career services!
          </p>
          <p className="k-text-justify k-mb-6">
            Your information will be automatically populated in the booking form
            if you were previously assisted by WSG or our partners (e.g. e2i,
            Ingeus and AKG). Please review the populated information to
            ensure that it is updated and accurate.
          </p>
          <p className="k-text-justify k-mb-6">
            If you are unable to show up for your appointment, we strongly
            encourage you to reschedule or cancel the appointment so that we can
            release the slot to others who are also in need of career advisory
            services. Failure to do so may result in delay of subsequent
            appointments.
          </p>
          <p className="k-text-justify k-mb-6">
            If you are currently being assisted by WSG or our partners, you will
            not be able to book an appointment. We encourage you to approach
            your assigned Career Officer for support and follow up.
          </p>
          <Button
            text={isCheckingL1 ? "Checking..." : "Next"}
            size="large"
            className="k-mt-5"
            onClick={onClickNext}
          />
        </div>
      </div>
    </div>
  );
};

export default KickStart;
