import { FormRenderProps } from "@progress/kendo-react-form";
import { EMPLOY_STATUS_OPTIONS } from "types/constants";

const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const numberRegex = new RegExp(/^[1-9]\d*$/);
const postalRegex = new RegExp(/^[0-9]\d*$/);
const htmlRegex = new RegExp(/<[^>]*>/g);

export const emailValidator = (value: string) =>
  emailRegex.test(value) ? "" : "You have entered an invalid email address!";
export const requiredValidator = (value: string) =>
  value ? "" : "This field is required";
export const numberValidator = (value: string) =>
  value && numberRegex.test(value) ? "" : "Please enter valid value";
export const postalCodeValidator = (value: string) =>
  value && postalRegex.test(value) ? "" : "Please enter valid value";
export const phoneValidator = (value: string) =>
  value && phoneRegex.test(value) && value.length <= 8 ? "" : "Please enter valid phone number.";
export const biographyValidator = (value: string) =>
  value && value.replace(htmlRegex, "").length >= 50
    ? ""
    : "Biography must be at least 50 characters long.";
export const requiredStrValidator = (value: string) =>
  value?.trim() ? "" : "This field is required";
export const isUnemployed = (formRenderProps: FormRenderProps) => {
  return (
    formRenderProps.valueGetter("employmentStatus") === EMPLOY_STATUS_OPTIONS[2]
  );
};

export const isEmployed = (formRenderProps: FormRenderProps) => {
  return (
    formRenderProps.valueGetter("employmentStatus") ===
      EMPLOY_STATUS_OPTIONS[0] ||
    formRenderProps.valueGetter("employmentStatus") === EMPLOY_STATUS_OPTIONS[1]
  );
};
