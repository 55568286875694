type HintProps = {
  text: string;
};

const Hint = ({ text }: HintProps) => {
  return (
    <div className="k-d-flex k-align-items-center fs-14 k-mt-2">
      <img src="/icons/hint.svg" className="h-26px k-mr-2" alt="hint" />
      {text}
    </div>
  );
};

export default Hint;
